import { FirebaseError } from 'firebase/app';
import { powersensorFirebaseAuthApi } from 'services/powersensor/firebase/api';

const ENDPOINT = '/auth';

export const getFirebaseAuthToken = async ({ idToken }: { idToken: string }): Promise<string> => {
  const body = { idtoken: idToken };
  try {
    const { data } = await powersensorFirebaseAuthApi.post(`${ENDPOINT}/firebase`, { body });
    return data.jwt;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error getting auth token', error);
    throw error;
  }
};

export const decodeFirebaseError = (error: FirebaseError): string => {
  switch (error.code) {
    case 'auth/cancelled-popup-request':
      return 'Popup request was cancelled.';
    case 'auth/invalid-email':
      return 'Invalid email address.';
    case 'auth/network-request-failed':
      return 'Network error. Please try again.';
    case 'auth/popup-blocked':
      return 'Popup blocked. Please enable popups in your browser.';
    case 'auth/operation-not-supported-in-this-environment':
      return 'This operation is not supported in this environment.';
    case 'auth/popup-closed-by-user':
      return 'Popup closed by user.';
    case 'auth/too-many-requests':
      return 'Too many login attempts. Please try again later.';
    case 'auth/user-not-found':
      return 'No user found with this email.';
    case 'auth/wrong-password':
      return 'Invalid password.';
    default:
      // eslint-disable-next-line no-console
      console.error('Unhandled Firebase error:', error);
      return 'A Firebase Auth error occurred. Please try again.';
  }
};
