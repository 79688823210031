import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import InputContainer from 'components/form/fields/InputContainer';
import { Dayjs } from 'dayjs';

type StartDateTariffProps = {
  value: Dayjs;
  onChange: (date: Dayjs | null) => void;
  error?: string;
  helperText?: string;
};

const StartDateTariff: React.FC<StartDateTariffProps> = ({ value, onChange, error }) => (
  <InputContainer title="Supply Start Date" description="When does your tariff start?" displayFlex={true}>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        value={value}
        format="DD/MM/YYYY"
        onChange={onChange}
        slotProps={{
          textField: {
            helperText: error,
            style: { width: '14rem' },
          },
        }}
      />
    </LocalizationProvider>
  </InputContainer>
);

export default StartDateTariff;
