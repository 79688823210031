import InputContainer from 'components/form/fields/InputContainer';
import TextFieldWithTypeOptions from 'components/TextFieldWithTypeOptions';
import { DAILY_SUPPLY_CHARGE_TYPE_OPTIONS } from 'utils/constants';

type SupplyChargeProps = React.ComponentProps<typeof TextFieldWithTypeOptions>;

const SupplyCharge: React.FC<SupplyChargeProps> = props => (
  <InputContainer
    title="Supply Charge"
    description="How much is the service to property charge?"
    helperText="An electricity tariff will include a fixed charge that is specified either by day, month or quarter. This is often called a 'Service to property', 'Daily supply charge'."
    displayFlex={true}
  >
    <TextFieldWithTypeOptions id="supplyChargeRate" adornmentOptions={DAILY_SUPPLY_CHARGE_TYPE_OPTIONS} type="number" {...props} />
  </InputContainer>
);

export default SupplyCharge;
