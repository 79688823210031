import styled from '@emotion/styled';
import { InputAdornment, TextField, TextFieldProps, Typography } from '@mui/material';

const UNITS = 'kWh';
const INFINITY = '∞';

type BlockTariffItemProps = {
  error?: string;
  isLast?: boolean;
  onChange: (newThreshold: string) => void;
  previousThreshold?: string;
  threshold: string;
};

const StyledContainer = styled.div`
  margin-bottom: 0.5rem;
`;

const CenteredFlexBox = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
`;

const StyledTextField = styled(TextField)`
  margin-left: 0.5rem;
  max-width: 8rem;
`;

const ThresholdField: React.FC<TextFieldProps> = props => (
  <StyledTextField
    {...props}
    InputProps={{
      endAdornment: <InputAdornment position="end">kWh</InputAdornment>,
    }}
  />
);

const BlockRange: React.FC<BlockTariffItemProps> = ({ previousThreshold, threshold, onChange, isLast = false, error }) => {
  const isInvalidThreshold = !isLast && (threshold.length === 0 || Number(threshold) <= Number(previousThreshold));
  const showThresholdError = isInvalidThreshold && !!error;
  const fromText = `${previousThreshold} ${UNITS}`;
  const toText = isLast ? `${INFINITY} ${UNITS}` : '';
  return (
    <StyledContainer>
      <CenteredFlexBox>
        <Typography sx={{ fontWeight: 'bold' }}>
          {fromText} → {toText}
        </Typography>
        {!isLast && (
          <ThresholdField type="number" error={isInvalidThreshold} value={threshold} onChange={e => onChange(e.target.value)} />
        )}
      </CenteredFlexBox>
      {showThresholdError && (
        <Typography variant="caption" color="error">
          {`Please enter a threshold greater than ${fromText}.`}
        </Typography>
      )}
    </StyledContainer>
  );
};

export default BlockRange;
