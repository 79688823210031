import { AccountInfo } from 'services/powersensor/types';
import PricingService from 'services/pricingService';
import { getIntervalPeaks } from 'utils/dataReader';
import { getTotalDaysFromIntervalData } from 'utils/timeHelper';
import { fillMissingIntervalData, pricingBreakdownToAnnualEstimate } from 'utils/transform';
import {
  CustomerTariffCostById,
  FeedData,
  IntervalData,
  IntervalPricingData,
  MonthlyIntervalPeaks,
  PricingBreakdown,
  Tariff,
  TariffCostById,
  Usage,
} from 'utils/types';
import { create } from 'zustand';

const isConsumptionKey = (key: string) => key.startsWith('E');
const isGenerationKey = (key: string) => key.startsWith('B');
const isTotalConsumptionKey = (key: string) => key.startsWith('A');
const isTotalSolarKey = (key: string) => key.startsWith('S');

const getLastAccumulatedPrice = (data: IntervalPricingData[]): PricingBreakdown => {
  const lastDayPricing = data.slice(-1)?.[0];
  const lastIntervalPricing = lastDayPricing?.intervals.slice(-1)?.[0];
  return (
    lastIntervalPricing?.accumulatedPrice ?? {
      supply: 0,
      consumption: 0,
      generation: 0,
      controlledLoad: [],
      controlledLoadTotalSupply: 0,
      controlledLoadTotalUsage: 0,
      demand: 0,
    }
  );
};

type Store = {
  accountInfo?: AccountInfo;
  authToken?: string;
  comparisonTariffs: Tariff[];
  consumptionData: IntervalData[];
  controlledLoadData: FeedData[];
  customerTariffs: Tariff[];
  customerTariffCostById: CustomerTariffCostById;
  generationData: IntervalData[];
  intervalPeaks: MonthlyIntervalPeaks;
  selectedAccount?: string;
  step: number;
  tariffCostsById: TariffCostById;
  totalConsumptionData: IntervalData[];
  totalDaysOfData: number;
  totalSolarData: IntervalData[];
  usage: Usage;
  addComparisonTariff: (comparisonTariff: Tariff) => void;
  addCustomerTariff: (customerTariff: Tariff) => void;
  clearAccountInfo: () => void;
  clearAuthToken: () => void;
  clearComparisonTariffs: () => void;
  goToStep: (step: number) => void;
  nextStep: () => void;
  previousStep: () => void;
  removeComparisonTariff: (comparisonTariff: Tariff) => void;
  removeCustomerTariff: (customerTariff: Tariff) => void;
  setAccountInfo: (accountInfo: AccountInfo) => void;
  setAuthToken: (authToken: string) => void;
  setSelectedAccount: (selectedAccount: string) => void;
  setStep: (step: number) => void;
  setUsage: (usage: Usage) => void;
};
export const useStore = create<Store>(set => ({
  accountInfo: undefined,
  authToken: undefined,
  comparisonTariffs: [],
  consumptionData: [],
  controlledLoadData: [],
  customerTariffs: [],
  customerTariffCostById: {},
  generationData: [],
  intervalData: [],
  intervalPeaks: {},
  selectedAccount: undefined,
  step: 0,
  totalConsumptionData: [],
  totalSolarData: [],
  tariffCostsById: {},
  totalDaysOfData: 0,
  usage: {},
  addComparisonTariff: (tariff: Tariff) =>
    set(
      ({
        comparisonTariffs,
        consumptionData,
        controlledLoadData,
        generationData,
        intervalPeaks,
        tariffCostsById,
        totalDaysOfData,
      }) => {
        const updatedComparisonTariffs = [...comparisonTariffs, tariff];
        if (tariffCostsById[tariff.id]) {
          return {
            comparisonTariffs: updatedComparisonTariffs,
            tariffCostsById,
          };
        }
        const tariffPricingForAllIntervals = PricingService.getPricingFromMeterDataAndTariff(
          consumptionData,
          generationData,
          controlledLoadData,
          tariff,
          intervalPeaks,
        );
        const lastIntervalPricing = getLastAccumulatedPrice(tariffPricingForAllIntervals);
        const pricingBreakdown = pricingBreakdownToAnnualEstimate(lastIntervalPricing, totalDaysOfData);
        return {
          comparisonTariffs: updatedComparisonTariffs,
          tariffCostsById: {
            ...tariffCostsById,
            [tariff.id]: pricingBreakdown,
          },
        };
      },
    ),
  clearAccountInfo: () => set({ accountInfo: undefined }),
  clearAuthToken: () => set({ authToken: undefined }),
  nextStep: () => set(({ step }) => ({ step: step + 1 })),
  previousStep: () => set(({ step }) => ({ step: step - 1 })),
  goToStep: (step: number) => set({ step }),
  removeComparisonTariff: (comparisonTariff: Tariff) =>
    set(({ comparisonTariffs, tariffCostsById }) => ({
      comparisonTariffs: comparisonTariffs.filter(tariff => tariff !== comparisonTariff),
      tariffCostsById: Object.keys(tariffCostsById).reduce(
        (acc, key) => (key === comparisonTariff.id ? acc : { ...acc, [key]: tariffCostsById[key] }),
        {},
      ),
    })),
  removeCustomerTariff: (customerTariff: Tariff) =>
    set(({ customerTariffs, tariffCostsById }) => ({
      customerTariffs: customerTariffs.filter(tariff => tariff !== customerTariff),
      tariffCostsById: Object.keys(tariffCostsById).reduce(
        (acc, key) => (key === customerTariff.id ? acc : { ...acc, [key]: tariffCostsById[key] }),
        {},
      ),
    })),
  setAccountInfo: (accountInfo: AccountInfo) => set({ accountInfo, selectedAccount: accountInfo.email }),
  setAuthToken: (authToken: string) => set({ authToken }),
  addCustomerTariff: (tariff: Tariff) =>
    set(
      ({
        consumptionData,
        controlledLoadData,
        customerTariffs,
        generationData,
        intervalPeaks,
        customerTariffCostById,
        totalDaysOfData,
      }) => {
        const tariffIndex = customerTariffs.findIndex(t => t.id === tariff.id);
        const newCustomerTariffs =
          tariffIndex === -1 ? [...customerTariffs, tariff] : customerTariffs.map((t, i) => (i === tariffIndex ? tariff : t));
        const tariffPricingForAllIntervals = PricingService.getPricingFromMeterDataAndTariff(
          consumptionData,
          generationData,
          controlledLoadData,
          tariff,
          intervalPeaks,
        );
        const withoutSolarPricing = generationData.length
          ? PricingService.getPricingFromMeterDataAndTariff(consumptionData, [], controlledLoadData, tariff, intervalPeaks)
          : [];
        const pricingBreakdownByDate = PricingService.getPricingBreakdownByDate(tariffPricingForAllIntervals);
        const lastAccumulatedPrice = getLastAccumulatedPrice(tariffPricingForAllIntervals);
        const breakdown = pricingBreakdownToAnnualEstimate(lastAccumulatedPrice, totalDaysOfData);
        const lastAccummulatedPriceWithoutSolar = getLastAccumulatedPrice(withoutSolarPricing);
        const breakdownWithoutSolar = pricingBreakdownToAnnualEstimate(lastAccummulatedPriceWithoutSolar, totalDaysOfData);

        return {
          customerTariffs: newCustomerTariffs,
          customerTariffCostById: {
            ...customerTariffCostById,
            [tariff.id]: {
              ...pricingBreakdownByDate,
              breakdown,
              breakdownWithoutSolar,
            },
          },
        };
      },
    ),
  setSelectedAccount: (selectedAccount: string) => set({ selectedAccount }),
  setUsage: (usage: Usage) => {
    // NOTE (pdiego): Currently we support only a single meter
    const meterData = Object.values(usage)[0];

    const consumptionFeedIds = Object.keys(meterData).filter(isConsumptionKey);
    const allConsumption = consumptionFeedIds.map(feedId => meterData[feedId]);
    const [consumptionData, ...controlledLoadData] = allConsumption;
    const totalDaysOfData = getTotalDaysFromIntervalData(consumptionData);
    const allEntries = Object.entries(meterData);
    // NOTE (pdiego): Assumption is that there'll be a single feed for solar
    const generationData = allEntries.find(entry => isGenerationKey(entry[0]))?.[1];
    const fullGenerationData = generationData ? fillMissingIntervalData(consumptionData, generationData) : [];
    const intervalPeaks = getIntervalPeaks(consumptionData);
    set({
      usage,
      tariffCostsById: {},
      customerTariffCostById: {},
      consumptionData,
      controlledLoadData: controlledLoadData.map(cl => fillMissingIntervalData(consumptionData, cl)),
      generationData: fullGenerationData,
      totalConsumptionData: allEntries.find(entry => isTotalConsumptionKey(entry[0]))?.[1] ?? [],
      totalDaysOfData,
      totalSolarData: allEntries.find(entry => isTotalSolarKey(entry[0]))?.[1] ?? [],
      comparisonTariffs: [],
      intervalPeaks,
    });
  },
  clearComparisonTariffs: () => set({ comparisonTariffs: [] }),
  setStep: step => set({ step }),
}));
