import { Dayjs } from 'dayjs';
import { AERPlanFee, AERPlanIncentive } from 'services/aer/types';

export type IntervalData = {
  date: Date;
  intervals: number[];
  suffix: string;
};

export type ControlledPricingBreakdown = {
  supply: number;
  rate: number;
};

export type PricingBreakdown = {
  supply: number;
  consumption: number;
  generation: number;
  controlledLoad: ControlledPricingBreakdown[];
  controlledLoadTotalSupply: number;
  controlledLoadTotalUsage: number;
  demand: number;
  total?: number;
};

export type IntervalPricing = {
  rates: PricingBreakdown;
  accumulatedPrice: PricingBreakdown;
  accumulatedKwh: {
    consumption: number;
    generation: number;
    controlledLoad: number[];
  };
  price: PricingBreakdown;
};

export type IntervalPricingData = {
  date: Date;
  intervals: IntervalPricing[];
};

export type TariffCostById = {
  [tariffId: string]: PricingBreakdown;
};

export type PricingBreakdownByDate = {
  byMonth: DatePricing[];
  byDay: DatePricing[];
};

export type CustomerTariffCostById = {
  [tariffId: string]: PricingBreakdownByDate & {
    breakdown: PricingBreakdown;
    breakdownWithoutSolar: PricingBreakdown;
  };
};

export type KwhAverage = {
  description: string; // e.g. '00:00 - 00:30', 'January', '01/01/2021'...
  kwh: number;
  total?: number;
  diff?: number;
};

export type PeriodType = 'peak' | 'off_peak' | 'shoulder';
export type SupplyChargeType = '¢/day' | '$/day' | '$/month' | '$/quarter';
export type TariffRateType = '$/kWh' | '¢/kWh';
export type TariffType = 'single' | 'time' | 'demand' | 'block';
export type TimeOfUseType = 'everyday' | 'weekdays-weekends';
export type BarPeriodType = 'month' | 'day';

export type Period = {
  type: PeriodType;
  daysOfTheWeekIndex: number[]; // O..6
  intervalsIndex: number[]; // 0..47
};

export type TariffRate = {
  startDate?: string; // 'MM-DD'
  endDate?: string; // 'MM-DD'
  rate: number;
  type: TariffRateType;
  period: Period;
  threshold?: number;
};

export type TariffSupply = {
  rate: number;
  type: SupplyChargeType;
};

export type DemandRate = Pick<TariffRate, 'rate' | 'type'>;

export type BlockCycle = {
  generation: TariffCycle;
  consumption: TariffCycle;
};

// NOTE (pdiego): At the moment we only support consumption percentage discounts
export enum TariffDiscountType {
  CONSUMPTION = 'consumption',
  BILL = 'bill',
}

export type TariffDiscount = {
  percentage: number;
  type: TariffDiscountType;
};

export type ControlledLoadRate = {
  supply: TariffSupply;
  rate: TariffRate;
};

export type Tariff = {
  id: string;
  startDate: Dayjs;
  retailer?: string;
  name?: string;
  supply: TariffSupply;
  eligibility: string[];
  demandRates: TariffRate[];
  consumptionRates: TariffRate[];
  generationRates: TariffRate[];
  controlledLoadRates: ControlledLoadRate[];
  cycle: BlockCycle;
  discounts?: TariffDiscount[];
  fees?: AERPlanFee[];
  incentives?: AERPlanIncentive[];
};

type ToggleOption<T> = {
  value: T;
  label: string;
};

export type ChannelOption = ToggleOption<TariffType>;

export type TimeOfUseOption = ToggleOption<TimeOfUseType>;

export type BarPeriodOption = ToggleOption<BarPeriodType>;

export type SeasonalVolumeRate = {
  rate: number;
  threshold?: number;
  startDate?: string; // MM-DD
  endDate?: string; // MM-DD
};

export type RatesByInterval = {
  [interval: number]: SeasonalVolumeRate[];
};

export type RatesByDayAndInterval = {
  [dayOfTheWeek: number]: RatesByInterval;
};

type DemandPeak = {
  days: number;
  maxKW: number;
  rate: number;
};

export type MonthlyDemandPeaks = {
  [month: string]: DemandPeak;
};

export type DayOfTheWeekIntervalPeaks = {
  [dayOfTheWeek: number]: number[];
};
export type DayOfTheWeekIndexedIntervalPeaks = {
  [dayOfTheWeek: number]: {
    [interval: number]: number;
  };
};

export type MonthlyIntervalPeaks = {
  [month: string]: {
    days: number;
    maxKWByDayOfTheWeek: DayOfTheWeekIntervalPeaks;
  };
};

export type TariffFormRate = {
  id: string;
  rate: string;
  type: TariffRateType;
  period: Period;
  threshold: string;
};

export type ControlledLoadFormRate = {
  rate: string;
  type: TariffRateType;
  supplyChargeRate: string;
  supplyChargeType: SupplyChargeType;
};

export type TariffCycle = 'monthly' | 'bi-monthly' | 'quarterly' | 'yearly';

export type TariffFormValues = {
  id?: string;
  name?: string;
  consumptionRates: TariffFormRate[];
  consumptionRateType: TariffRateType;
  consumptionTariffType: TariffType;
  consumptionTariffCycle: TariffCycle;
  demandRates: TariffFormRate[];
  demandRateType: TariffRateType;
  generationRates: TariffFormRate[];
  controlledLoadRates: ControlledLoadFormRate[];
  generationRateType: TariffRateType;
  generationTariffType: TariffType;
  generationTariffCycle: TariffCycle;
  hasSolar: boolean;
  startDate?: Dayjs;
  supplyChargeRate: string;
  supplyChargeType: SupplyChargeType;
};

export type PieInterval = {
  index: number;
  value: number;
  rate?: string;
  type?: string;
  periodType?: PeriodType;
};

export type DatePricing = PricingBreakdown & {
  date: string;
};

export type MonthDatePricing = DatePricing & {
  totalDaysData: number;
};

export type TariffWithBreakdownAndTotal = {
  tariff: Tariff;
  breakdown: PricingBreakdown;
  total: number;
};

export type MeterId = string;
export type Usage = Record<MeterId, MeterData>;
export type PowerSensorUsageKey = 'A1' | 'B1' | 'E1' | 'S1';
// For Powersensor data we do not have a meter id, so we use a generic 'powersensor' key
// and currently the Powersensor data does not support multiple feeds, so we assume single feeds
// and use the appropriate suffixes for the data IAW NEM12 spec.
// As a result, Powersensor data will always have the following suffixes: B1, E1, S1, A1 and does not
// support multiple meters or feeds, such as users with controlled loads.
export type PowerSensorUsage = Usage & {
  powersensor: {
    A1: IntervalData[];
    B1?: IntervalData[];
    E1: IntervalData[];
    S1?: IntervalData[];
  };
};

export type FeedId = string;
export type MeterData = Record<FeedId, FeedData>;

export type FeedData = IntervalData[];
