import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { ALL_INTERVAL_INDEXES, INTERVAL_LENGTH, WEEKDAY_DAY_INDEXES, WEEKEND_DAY_INDEXES } from 'utils/constants';
import { IntervalData, Period, PeriodType } from 'utils/types';

export const periodTypeToString = (periodType: PeriodType): string => periodType.replaceAll(/_/g, '-');

const validateInterval = (interval: number): void => {
  if (interval > 47) throw new Error('interval must be less than 48');
};

export const getHourFromInterval = (interval: number, start = true, intervalDuration = 30): number => {
  validateInterval(interval);
  const intervalsInAnHour = 60 / intervalDuration;
  const position: number = start ? interval : interval + 1;
  return Math.floor(position / intervalsInAnHour);
};

export const getMinutesFromInterval = (interval: number, start = true, intervalDuration = 30): number => {
  validateInterval(interval);
  const intervalsInAnHour = 60 / intervalDuration;
  const position: number = start ? interval : interval + 1;
  return intervalDuration * (position % intervalsInAnHour);
};

export const intervalArrayToHourString = (intervals: number[]): string => {
  if (intervals.length === 0) return '-';
  if (intervals.length === 1) return intervalPositionToHourString(intervals[0]);
  return `${intervalPositionToHourString(intervals[0])} - ${intervalPositionToHourString(intervals[intervals.length - 1], false)}`;
};

export const splitHourString = (intervals: number[] | null): string[] | null =>
  intervals
    ? intervalArrayToHourString(intervals)
        .split('-')
        .map(t => t.trim())
        .filter(t => t !== '')
    : null;

export const intervalPositionToHourString = (interval: number, start = true, onlyHour = false): string => {
  validateInterval(interval);
  const rawHour = getHourFromInterval(interval, start);
  const isAM: boolean = rawHour === 24 || rawHour < 12;
  const hour = rawHour % 12 || 12;
  const minute = getMinutesFromInterval(interval, start);
  const minString = `${minute}`.padStart(2, '0');
  const minAppendix = onlyHour ? '' : `:${minString}`;
  return `${hour}${minAppendix}${isAM ? 'am' : 'pm'}`;
};

export const dayJsToInterval = (dayjs: Dayjs): number => dayjs.hour() * 2 + (!!dayjs.minute() ? 1 : 0);

export const getIntervalsFromDates = (beginTime: Dayjs, endTime: Dayjs): number[] => {
  const fromInterval: number = dayJsToInterval(beginTime);
  const toInterval: number = dayJsToInterval(endTime);
  const isNextDay: boolean = fromInterval >= toInterval;
  const intervalLength: number = isNextDay ? toInterval + INTERVAL_LENGTH - fromInterval : toInterval - fromInterval;
  return Array.from(Array(intervalLength).keys()).map(t => (t + fromInterval) % INTERVAL_LENGTH);
};

export const getTotalDaysFromDates = (startDate: Date, endDate: Date): number =>
  Math.round((endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24)) + 1;

export const getTotalDaysFromIntervalData = (meterData: IntervalData[]): number => {
  const startDate: Date = meterData[0].date;
  const endDate: Date = meterData[meterData.length - 1].date;
  return getTotalDaysFromDates(startDate, endDate);
};

export const unifyPeriods = (periods: Period[]): Period | undefined => {
  const result = periods.reduce((acc: Period | undefined, current: Period) => {
    if (acc) {
      return {
        ...acc,
        intervalsIndex: [...acc.intervalsIndex, ...current.intervalsIndex],
      };
    }
    return current;
  }, undefined);
  return result;
};

export const getMissedIntervals = (periods: Period[]): number[] =>
  ALL_INTERVAL_INDEXES.filter(interval => !unifyPeriods(periods)?.intervalsIndex.includes(interval));

const getMissedIntervalsForGivenDays = (periods: Period[], dayIndexes: number[]): number[] =>
  getMissedIntervals(periods.filter(p => dayIndexes.every(d => p.daysOfTheWeekIndex.includes(d))));

const getMissingPeriods = (periods: Period[]): Period[] => {
  const weekdayPeriod: Period = {
    type: 'peak',
    daysOfTheWeekIndex: WEEKDAY_DAY_INDEXES,
    intervalsIndex: getMissedIntervalsForGivenDays(periods, WEEKDAY_DAY_INDEXES),
  };
  const weekendPeriod: Period = {
    type: 'peak',
    daysOfTheWeekIndex: WEEKEND_DAY_INDEXES,
    intervalsIndex: getMissedIntervalsForGivenDays(periods, WEEKEND_DAY_INDEXES),
  };

  return [weekdayPeriod, weekendPeriod].filter(p => p.intervalsIndex.length);
};

export const hasAllPeriods = (periods: Period[]): boolean => !getMissingPeriods(periods).length;

export const applyTimezone = (date: string, tz: string): Dayjs => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  return dayjs(date).tz(tz);
};

const extractOffset = (timeString: string): string => {
  // Define the regex pattern for a timezone offset
  const offsetPattern = /[+-]\d{2}:\d{2}$/;
  return timeString.match(offsetPattern)?.[0] ?? '';
};

/**
 * @param timeString - A string in the format 'HHMM'
 * @returns a Dayjs object with the time set to the given timeString
 * In some cases, the timeString might be '2359' or '2329', so we rounded up to interval fraction.
 * In some other cases may have timezone offset, so we need to extract the offset and adjust the time accordingly.
 * Finally sometimes is formatted as 23:55 or even 0::00, so we need to remove the colons.
 */
export const convertTimeStringToDayjs = (timeString: string): Dayjs => {
  // Remove any colons from the time string
  const cleanedTimeString = timeString.replace(/:/g, '');
  const sanitizedTimeString = cleanedTimeString.padStart(4, '0');
  const [hour, minute] = sanitizedTimeString.match(/\d{2}/g)?.map(Number) ?? [0, 0];
  const offset = extractOffset(timeString);
  const symbol = offset[0];
  const isAddition = symbol === '+';
  const [hourOffset, minuteOffset] = offset.match(/\d{2}/g)?.map(Number) ?? [0, 0];
  const totalHour = isAddition ? hour + hourOffset : hour - hourOffset;
  const totalMinute = isAddition ? minute + minuteOffset : minute - minuteOffset;
  if (minute % 30 === 0) return dayjs().hour(totalHour).minute(totalMinute);
  const sanitizedMinute = (Math.ceil(totalMinute / 30) * 30) % 60;
  const sanitizedHour = sanitizedMinute === 0 ? totalHour + 1 : totalHour;
  return dayjs().hour(sanitizedHour).minute(sanitizedMinute);
};

export const dayIndexesToText = (dayIndexes: number[]): string => {
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  if (dayIndexes.length === 7) return 'Everyday';
  if (dayIndexes === WEEKEND_DAY_INDEXES) return 'Weekend';
  const firstDay = days[dayIndexes[0]];
  const lastDay = days[dayIndexes[dayIndexes.length - 1]];
  return dayIndexes.length === 1 ? firstDay : `${firstDay} - ${lastDay}`;
};

export const isMonthInRange = (month: string, startDate = '', endDate = ''): boolean => {
  if (!startDate || !endDate) return true;
  const [startMonth, startDay] = startDate.split('-');
  const [endMonth, endDay] = endDate.split('-');
  const targetMonth = Number(month);
  const startMonthNumber = Number(startMonth);
  const endMonthNumber = Number(endMonth);
  const startDayNumber = Number(startDay);
  const endDayNumber = Number(endDay);

  // NOTE(pdiego): this is to handle observed scenarios such as startDate 0705 and endDate 0704
  if (startMonthNumber === endMonthNumber && Math.abs(startDayNumber - endDayNumber) === 1) return true;
  return startMonthNumber > endMonthNumber
    ? targetMonth <= endMonthNumber || targetMonth >= startMonthNumber
    : targetMonth >= startMonthNumber && targetMonth <= endMonthNumber;
};

export const getFormattedMonth = (date: Date, monthOnly = false) => {
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const formattedMonth = `${month < 10 ? '0' : ''}${month}`;

  return monthOnly ? formattedMonth : `${year}-${formattedMonth}`;
};

export const getMonthName = (monthNumber: string): string => dayjs(`${monthNumber}-01`).format('MMM');

export const getDaysInMonth = (month: number, year: number) => new Date(year, month, 0).getDate();

/**
 * @param startDateString start date in the format 'MM-DD' or 'YYYY-MM-DD'
 * @param endDateString end date in the format 'MM-DD' or 'YYYY-MM-DD'
 * @returns season without the year. In some cases startDate and endDate are in different years (i.e.: startDate 2024 - endDate 2030),
 * in those cases we assume the tariff applies all year.
 */
export const removeYear = (startDateString = '', endDateString = ''): [string, string] => {
  const regex = /\d{4}-/;
  if (!startDateString || !endDateString) return ['01-01', '12-31'];
  const startYear = regex.test(startDateString) ? Number(startDateString.slice(0, 4)) : 0;
  const endYear = regex.test(endDateString) ? Number(endDateString.slice(0, 4)) : 0;
  const startDateWithoutYear = startDateString.replace(regex, '');
  const endDateWithoutYear = endDateString.replace(regex, '');
  if (endYear - startYear > 1) return ['01-01', '12-31'];
  if (endYear - startYear === 1) {
    const startDateMonth = Number(startDateWithoutYear.split('-')[0]);
    const endDateMonth = Number(endDateWithoutYear.split('-')[0]);
    if (endDateMonth >= startDateMonth) return ['01-01', '12-31'];
    return [startDateWithoutYear, endDateWithoutYear];
  }
  return [startDateWithoutYear, endDateWithoutYear];
};
