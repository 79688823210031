import InputContainer from 'components/form/fields/InputContainer';
import ToggleSelectionButtonGroup from 'components/ToggleSelectionButtonGroup';
import { GENERATION_TYPE_OPTIONS } from 'utils/constants';

type ToggleGenerationTariffTypeProps = {
  value?: string;
  onChange: (value: string) => void;
};

const ToggleGenerationTariffType: React.FC<ToggleGenerationTariffTypeProps> = props => (
  <>
    <InputContainer
      title="Feed-in Tariff Type"
      description="What type of feed-in tariff do you have?"
      helperText="You can find tariff information on your energy bill."
    />
    <ToggleSelectionButtonGroup options={GENERATION_TYPE_OPTIONS} {...props} />
  </>
);

export default ToggleGenerationTariffType;
