import dayjs from 'dayjs';
import Decimal from 'decimal.js';
import {
  AERControlledLoadSingleRate,
  AERControlledLoadTOURate,
  AERDay,
  AERPayerType,
  AERPlanControlledLoadV2,
  AERPlanControlledTariffBlockType,
  AERPlanDetailV3,
  AERPlanDiscount,
  AERPlanDiscountMethod,
  AERPlanDiscountType,
  AERPlanSolarFeedInTariffV3,
  AERPlanTariffBlockType,
  AERPlanTariffPeriod2,
  AERScheme,
  AERSolarTariffType,
  AERTariffDemandCharge,
  AERTariffRate,
  AERTariffTimeOfUseRate,
  AERTariffTimeOfUseType,
} from 'services/aer/types';
import { ALL_DAY_INDEXES, ALL_INTERVAL_INDEXES, GST_RATE, MONTHS_IN_YEAR, YEAR_DURATION } from 'utils/constants';
import { convertTimeStringToDayjs, getIntervalsFromDates, removeYear } from 'utils/timeHelper';
import {
  ControlledLoadRate,
  Period,
  PeriodType,
  Tariff,
  TariffDiscount,
  TariffDiscountType,
  TariffRate,
  TariffSupply,
} from 'utils/types';

const gstInclusive = (rate: number) => new Decimal(rate).plus(new Decimal(rate).times(GST_RATE)).toDecimalPlaces(4).toNumber();

const getSupplyRate = (tariffPeriod: AERPlanTariffPeriod2[]): TariffSupply => {
  const dailySupplyCharges = tariffPeriod.find(t => t.dailySupplyCharges)?.dailySupplyCharges;
  return {
    rate: dailySupplyCharges ? gstInclusive(Number(dailySupplyCharges)) : 0,
    type: '¢/day',
  };
};

export const getTariffRate = ({
  startDate = '01-01',
  endDate = '12-31',
  unitPrice,
  type = 'peak',
  days = ALL_DAY_INDEXES,
  intervals = ALL_INTERVAL_INDEXES,
  threshold,
}: {
  startDate?: string;
  endDate?: string;
  unitPrice: string;
  type?: PeriodType;
  days?: number[];
  intervals?: number[];
  threshold?: number;
}): TariffRate => {
  const [sanitizedStartDate, sanitizedEndDate] = removeYear(startDate, endDate);
  return {
    startDate: sanitizedStartDate,
    endDate: sanitizedEndDate,
    rate: gstInclusive(Number(unitPrice)),
    type: '¢/kWh',
    period: {
      type,
      daysOfTheWeekIndex: days,
      intervalsIndex: intervals,
    },
    threshold,
  };
};

const AERTariffTimeOfUseTypeToPeriodType = (type: AERTariffTimeOfUseType): PeriodType => {
  switch (type) {
    case AERTariffTimeOfUseType.OFF_PEAK:
      return 'off_peak';
    case AERTariffTimeOfUseType.PEAK:
      return 'peak';
    default:
      return 'shoulder';
  }
};

const AERDayToDayOfTheWeekIndex = (day: AERDay): number[] => {
  switch (day) {
    case AERDay.SUNDAY:
    case AERDay.SUNDAY_FULL:
      return [0];
    case AERDay.MONDAY:
    case AERDay.MONDAY_FULL:
      return [1];
    case AERDay.TUESDAY:
    case AERDay.TUESDAY_FULL:
      return [2];
    case AERDay.WEDNESDAY:
    case AERDay.WEDNESDAY_FULL:
      return [3];
    case AERDay.THURSDAY:
    case AERDay.THURSDAY_FULL:
      return [4];
    case AERDay.FRIDAY:
    case AERDay.FRIDAY_FULL:
      return [5];
    case AERDay.SATURDAY:
    case AERDay.SATURDAY_FULL:
      return [6];
    case AERDay.BUSINESS_DAYS:
      return [1, 2, 3, 4, 5];
    case AERDay.PUBLIC_HOLIDAYS:
      // NOTE (pdiego): Our model does not support this currently
      // eslint-disable-next-line no-console
      console.warn('PUBLIC_HOLIDAYS not supported');
      return [-1];
  }
};
const AERDaysToDayIndexes = (days?: AERDay[]): number[] =>
  days?.flatMap(AERDayToDayOfTheWeekIndex).filter(d => d !== -1) ?? ALL_DAY_INDEXES;

const demandChargeToTariffRateWithDates =
  (startDate?: string, endDate?: string) =>
  ({ days, startTime, endTime, amount, chargePeriod }: AERTariffDemandCharge): TariffRate => {
    const dayDemandRate =
      chargePeriod === 'MONTH' ? new Decimal(amount).times(MONTHS_IN_YEAR).dividedBy(YEAR_DURATION).toNumber() : Number(amount);
    return getTariffRate({
      startDate,
      endDate,
      unitPrice: dayDemandRate.toString(),
      days: AERDaysToDayIndexes(days),
      intervals: getIntervalsFromDates(convertTimeStringToDayjs(startTime), convertTimeStringToDayjs(endTime)),
      type: 'peak',
    });
  };

const solarTariffToTariffRate = (solarFeedInTariff: AERPlanSolarFeedInTariffV3): TariffRate[] => {
  const [startDate, endDate] = removeYear(solarFeedInTariff.startDate, solarFeedInTariff.endDate);
  switch (solarFeedInTariff.tariffUType) {
    case AERSolarTariffType.SINGLE_TARIFF:
      const periodType: PeriodType = 'peak';
      const period = {
        type: periodType,
        daysOfTheWeekIndex: ALL_DAY_INDEXES,
        intervalsIndex: ALL_INTERVAL_INDEXES,
      };
      const singleRates = solarFeedInTariff.singleTariff?.rates ?? [];
      return singleRates.map(rate => ({
        startDate,
        endDate,
        rate: Number(rate.unitPrice), // NOTE (pdiego): No need to include GST
        type: '¢/kWh',
        period,
        threshold: rate.volume,
      }));
    case AERSolarTariffType.TIME_VARYING_TARIFF:
      const { rates = [], timeVariations = [], type = AERTariffTimeOfUseType.PEAK } = solarFeedInTariff.timeVaryingTariffs ?? {};
      if (rates.length > 1) {
        // NOTE (pdiego): We have not seen multiple rates here yet. If they exist, we need to find out how to handle them.
        // Is the order of the timeVariations and rates arrays related?
        // eslint-disable-next-line no-console
        console.error('NOT IMPLEMENTED', solarFeedInTariff);
      }
      const timeVaryingRate = rates[0];
      const periodsFromTimeVariations: Period[] = timeVariations.map(({ days, startTime = '0000', endTime = '2359' }) => ({
        type: AERTariffTimeOfUseTypeToPeriodType(type),
        daysOfTheWeekIndex: AERDaysToDayIndexes(days),
        intervalsIndex: getIntervalsFromDates(convertTimeStringToDayjs(startTime), convertTimeStringToDayjs(endTime)),
      }));
      const result: TariffRate[] = periodsFromTimeVariations.map(period => ({
        startDate,
        endDate,
        rate: Number(timeVaryingRate.unitPrice), // NOTE (pdiego): No need to include GST
        type: '¢/kWh',
        period,
        threshold: timeVaryingRate.volume,
      }));
      return result;
  }
};

const flatRateToTariffRateWithDates =
  (startDate?: string, endDate?: string) =>
  ({ unitPrice, volume: threshold }: AERTariffRate): TariffRate =>
    getTariffRate({
      startDate,
      endDate,
      unitPrice,
      threshold,
    });

const timeOfUseRateToTariffRatesWithDates =
  (startDate?: string, endDate?: string) =>
  (r: AERTariffTimeOfUseRate): TariffRate[] =>
    r.timeOfUse.flatMap(({ days, startTime, endTime }) =>
      r.rates.flatMap(rate =>
        getTariffRate({
          startDate,
          endDate,
          unitPrice: rate.unitPrice,
          days: AERDaysToDayIndexes(days),
          intervals: getIntervalsFromDates(convertTimeStringToDayjs(startTime), convertTimeStringToDayjs(endTime)),
          type: AERTariffTimeOfUseTypeToPeriodType(r.type),
          threshold: rate.volume,
        }),
      ),
    );

const tariffPeriodToTariffRate = (tariffPeriod: AERPlanTariffPeriod2): TariffRate[] => {
  const { startDate, endDate, singleRate, timeOfUseRates = [], demandCharges = [], rateBlockUType } = tariffPeriod;
  switch (rateBlockUType) {
    case AERPlanTariffBlockType.SINGLE_RATE:
      const singleRates = singleRate?.rates ?? [];
      return singleRates.map(flatRateToTariffRateWithDates(startDate, endDate));
    case AERPlanTariffBlockType.TIME_OF_USE:
      return timeOfUseRates.flatMap(timeOfUseRateToTariffRatesWithDates(startDate, endDate));
    case AERPlanTariffBlockType.DEMAND:
      return demandCharges.map(demandChargeToTariffRateWithDates(startDate, endDate));
  }
};

// NOTE (pdiego): We've only seen rates as an array with a single item.
const controlledLoadToControlledLoadRate = ({
  singleRate,
  timeOfUseRates = [],
  rateBlockUType,
}: AERPlanControlledLoadV2): ControlledLoadRate[] => {
  switch (rateBlockUType) {
    case AERPlanControlledTariffBlockType.SINGLE_RATE:
      if (!singleRate) {
        return [];
      }
      return [singleRateCLToControlledLoadRate(singleRate)];
    case AERPlanControlledTariffBlockType.TIME_OF_USE:
      return timeOfUseRates.flatMap(timeOfUseRateCLToControlledLoadRate);
  }
};

const singleRateCLToControlledLoadRate = ({ dailySupplyCharge, rates }: AERControlledLoadSingleRate): ControlledLoadRate => ({
  supply: {
    rate: gstInclusive(Number(dailySupplyCharge)),
    type: '¢/day',
  },
  rate: getTariffRate({
    unitPrice: rates[0].unitPrice,
  }),
});

const timeOfUseRateCLToControlledLoadRate = ({
  dailySupplyCharge,
  rates,
  timeOfUse,
}: AERControlledLoadTOURate): ControlledLoadRate[] =>
  timeOfUse.flatMap(({ days, startTime, endTime }) =>
    rates.map(rate => ({
      supply: {
        rate: gstInclusive(Number(dailySupplyCharge)),
        type: '¢/day',
      },
      rate: getTariffRate({
        unitPrice: rate.unitPrice,
        days: AERDaysToDayIndexes(days),
        intervals: getIntervalsFromDates(convertTimeStringToDayjs(startTime), convertTimeStringToDayjs(endTime)),
      }),
    })),
  );

const SUPPORTED_DISCOUNT_METHODS = [AERPlanDiscountMethod.PERCENT_OF_BILL, AERPlanDiscountMethod.PERCENT_OF_USE];

const isSupportedDiscount = ({ type, methodUType }: AERPlanDiscount): boolean =>
  type === AERPlanDiscountType.GUARANTEED && SUPPORTED_DISCOUNT_METHODS.includes(methodUType);

const aerDiscountToTariffDiscount = (discount: AERPlanDiscount): TariffDiscount => {
  const percentage = Number(discount.percentOfUse?.rate ?? discount.percentOfBill?.rate ?? 0);
  const type =
    discount.methodUType === AERPlanDiscountMethod.PERCENT_OF_BILL ? TariffDiscountType.BILL : TariffDiscountType.CONSUMPTION;
  return { percentage, type };
};

export const AERTariffToTariff = (aerPlanDetail: AERPlanDetailV3): Tariff => {
  const { electricityContract, displayName: name, brandName: retailer } = aerPlanDetail;
  const {
    solarFeedInTariff = [],
    tariffPeriod,
    discounts = [],
    controlledLoad = [],
    eligibility = [],
    fees = [],
    incentives,
  } = electricityContract;

  const retailerSolarRates = solarFeedInTariff.filter(s => s.payerType === AERPayerType.RETAILER);

  // NOTE (pdiego): Sometimes demandCharges is an array present on the initial tariffPeriod
  const demandCharges = tariffPeriod[0].demandCharges ?? [];
  const datePeriods = tariffPeriod.map(t => ({
    startDate: t.startDate,
    endDate: t.endDate,
  }));
  const convolutedDemandCharges = demandCharges.length
    ? demandCharges.map((d, i) => demandChargeToTariffRateWithDates(datePeriods[i].startDate, datePeriods[i].endDate)(d))
    : [];

  const { demand, consumption: consumptionRates } = tariffPeriod.reduce(
    (acc, t) => {
      if (t.rateBlockUType === AERPlanTariffBlockType.DEMAND) {
        return {
          ...acc,
          demand: [...acc.demand, ...tariffPeriodToTariffRate(t)],
        };
      }
      return {
        ...acc,
        consumption: [...acc.consumption, ...tariffPeriodToTariffRate(t)],
      };
    },
    {
      demand: [] as TariffRate[],
      consumption: [] as TariffRate[],
    },
  );

  const demandRates = [...demand, ...convolutedDemandCharges];

  return {
    id: aerPlanDetail.planId.split('@')[0],
    startDate: dayjs(),
    name,
    retailer,
    eligibility: eligibility.map(e => e.information),
    supply: getSupplyRate(tariffPeriod),
    consumptionRates,
    controlledLoadRates: controlledLoad.flatMap(controlledLoadToControlledLoadRate),
    // NOTE (pdiego): Premium schemes are no longer available
    generationRates: retailerSolarRates.filter(r => r.scheme !== AERScheme.PREMIUM).flatMap(solarTariffToTariffRate),
    demandRates,
    cycle: {
      generation: 'yearly',
      consumption: 'yearly',
    },
    discounts: discounts.filter(isSupportedDiscount).map(aerDiscountToTariffDiscount),
    fees,
    incentives,
  };
};
