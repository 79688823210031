import styled from '@emotion/styled';
import React from 'react';

const BouncingLoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 2rem auto;
`;

const BouncingDot = styled.div`
  width: 1rem;
  height: 1rem;
  margin: 0.25rem 0.5rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.primary.main};
  opacity: 1;
`;

const BouncingDotsLoader: React.FC = () => {
  return (
    <BouncingLoaderContainer className="bouncing-loader">
      <BouncingDot />
      <BouncingDot />
      <BouncingDot />
    </BouncingLoaderContainer>
  );
};

export default BouncingDotsLoader;
