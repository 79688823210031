import styled from '@emotion/styled';

const DashedDivider = styled.div`
  margin: 2rem auto;
  max-width: 30rem;
  width: 100%;
  border-bottom: 2px dotted ${({ theme }) => theme.palette.divider};
`;

export default DashedDivider;
