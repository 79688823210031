import { Typography } from '@mui/material';
import StyledCardContent from 'components/common/card/StyledCardContent';
import StyledCard from 'components/common/card/StyledCard';
import { ReactNode } from 'react';

interface CardContainerProps {
  title?: string;
  caption?: string;
  children?: ReactNode;
}

const CardContainer: React.FC<CardContainerProps> = ({ caption, title, children }) => (
  <StyledCard>
    <StyledCardContent>
      <div>
        {title && (
          <Typography variant="body1" fontWeight="bold">
            {title}
          </Typography>
        )}
        {caption && (
          <Typography variant="caption" fontWeight="bold">
            {caption}
          </Typography>
        )}
      </div>
      {children}
    </StyledCardContent>
  </StyledCard>
);

export default CardContainer;
