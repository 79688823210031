import Decimal from 'decimal.js';
import { PowerSensorAccountData, PowerSensorDataItem } from 'services/powersensor/types';
import { EMPTY_INTERVALS, INTERVAL_LENGTH, RECORD_INDICATORS, VALID_INTERVAL_LENGTHS } from 'utils/constants';
import {
  applyTimezone,
  dayJsToInterval,
  getFormattedMonth,
  getTotalDaysFromDates,
  intervalPositionToHourString,
} from 'utils/timeHelper';
import { decimalToNumber } from 'utils/transform';
import {
  DayOfTheWeekIntervalPeaks,
  IntervalData,
  KwhAverage,
  MeterData,
  MonthlyDemandPeaks,
  MonthlyIntervalPeaks,
  PowerSensorUsage,
  PowerSensorUsageKey,
  Usage,
} from 'utils/types';

/**
 * Standardize the interval data to 48 intervals of 30 mins.
 *
 * @param intervals an array of interval data in either 5, 15 or 30 min intervals.
 * @returns an array of interval data for each 30 mins
 */
export const standardizeIntervalData = (intervals: number[]): number[] => {
  const chunkSize = intervals.length / INTERVAL_LENGTH;

  return intervals.reduce((acc: number[], current, i) => {
    const chunkIndex = Math.floor(i / chunkSize);
    if (!acc[chunkIndex]) {
      acc[chunkIndex] = 0;
    }
    acc[chunkIndex] += current;

    return acc;
  }, []);
};

export const mapIntervalFromValues = (values: string[], suffix: string, intervalDuration: number): IntervalData => {
  const stringDay = values[1]; // YYYYMMDD
  const year = Number(stringDay.substring(0, 4));
  const monthIndex = Number(stringDay.substring(4, 6)) - 1;
  const day = Number(stringDay.substring(6, 8));
  const date: Date = new Date(year, monthIndex, day);

  const intervalCount = (60 * 24) / intervalDuration;
  const intervalData: number[] = values.slice(2, intervalCount + 2).map(Number);
  const intervals = standardizeIntervalData(intervalData);

  return { date, intervals, suffix };
};

type UsageDataReducerResult = {
  data: Usage;
  currentMeter: string;
  currentSuffix: string;
  currentIntervalDuration: number;
};

export const rowToUsageDataReducer = (result: UsageDataReducerResult, current: Record<string, string>) => {
  const values = Object.values(current);
  const recordCode = Number(values[0]);
  // Read Section 4 in: https://www.aemo.com.au/-/media/files/stakeholder_consultation/consultations/nem-consultations/2021/5ms-consolidation-process/final-stage/mdff-specification-nem12-nem13-v24-clean.pdf?la=en
  switch (recordCode) {
    case 200: // NMI data details record
      result.data[values[1]] = {
        ...result.data[values[1]],
        [values[4]]: [],
      };

      return {
        data: result.data,
        currentMeter: values[1],
        currentSuffix: values[4],
        currentIntervalDuration: Number(values[8]),
      };
    case 300: // Interval data record
      const feedData = result.data[result.currentMeter]?.[result.currentSuffix] || [];
      const intervalData = mapIntervalFromValues(values, result.currentSuffix, result.currentIntervalDuration);
      result.data[result.currentMeter][result.currentSuffix] = [...feedData, intervalData];

      return result;
    default:
      break;
  }
  return result;
};

export const csvRowsToUsageData = (csvRows: Record<string, string>[]): Usage =>
  csvRows.reduce<UsageDataReducerResult>(rowToUsageDataReducer, {
    data: {},
    currentMeter: '',
    currentSuffix: '',
    currentIntervalDuration: 0,
  }).data;

export const isValidNmiSuffix = (nmiSuffix: string): boolean => {
  const nmiSuffixTest = /^[ABES]\d$/;
  return nmiSuffixTest.test(nmiSuffix);
};

const isValidInterval = (interval: number): boolean => VALID_INTERVAL_LENGTHS.includes(interval);

const isValidRecord = (record: object) => {
  const values = Object.values(record);
  const code = Number(values[0]);
  if (code === 200) {
    return isValidNmiSuffix(values[4]) && isValidInterval(Number(values[8]));
  }
  return RECORD_INDICATORS.includes(code);
};

export const isValidIntervalData = (csvRows: object[]): boolean => csvRows.every(isValidRecord);

type MeterDataInfo = {
  endDate: Date;
  kwhDay: number;
  totalDay?: number;
  dateAverage: KwhAverage[];
  intervalAverage: KwhAverage[];
  startDate: Date;
  totalDays: number;
};
export const getInfoFromMeterData = (meterData: IntervalData[], totalData?: IntervalData[]): MeterDataInfo => {
  if (!meterData.length) {
    // eslint-disable-next-line no-console
    console.warn('No meter data found');
    return {
      dateAverage: [],
      endDate: new Date('1970-01-01'),
      intervalAverage: [],
      kwhDay: 0,
      startDate: new Date('1970-01-01'),
      totalDays: 0,
    };
  }
  const startDate: Date = meterData[0].date;
  const endDate: Date = meterData[meterData.length - 1].date;
  const totalDays: number = getTotalDaysFromDates(startDate, endDate);
  const kwhDay =
    meterData
      .map(interval => interval.intervals)
      .flat()
      .reduce((sum, current) => sum + current, 0) / totalDays;
  const totalDay = totalData
    ? decimalToNumber(
        new Decimal(
          totalData
            .map(interval => interval.intervals)
            .flat()
            .reduce((sum, current) => sum + current, 0),
        ).dividedBy(totalDays),
      )
    : undefined;
  const dateAverage: KwhAverage[] = meterData.map((dateData, i) => {
    const description = dateData.date.toLocaleDateString();
    const kwh = decimalToNumber(new Decimal(dateData.intervals.reduce((sum, current) => sum + current, 0)));
    const total = totalData?.length
      ? decimalToNumber(new Decimal(totalData[i].intervals.reduce((sum, current) => sum + current, 0)))
      : undefined;
    const diff = total && total > kwh ? decimalToNumber(new Decimal(total).minus(kwh)) : undefined;
    return {
      description,
      kwh,
      total,
      diff,
    };
  });
  const intervalAverage: KwhAverage[] = meterData[0].intervals.map((_, i) => {
    const description = `${intervalPositionToHourString(i)} - ${intervalPositionToHourString(i, false)}`;
    const kwh = decimalToNumber(
      new Decimal(meterData.map(m => m.intervals[i] ?? 0).reduce((sum, current) => sum + current, 0)).dividedBy(totalDays),
    );
    const total = totalData?.length
      ? decimalToNumber(
          new Decimal(totalData.map(m => m.intervals[i] ?? 0).reduce((sum, current) => sum + current, 0)).dividedBy(totalDays),
        )
      : undefined;
    const diff = total && total > kwh ? decimalToNumber(new Decimal(total).minus(kwh)) : undefined;
    return { description, kwh, total, diff };
  });
  return {
    dateAverage,
    endDate,
    kwhDay,
    totalDay,
    intervalAverage,
    startDate,
    totalDays,
  };
};

export type PowerSensorUsageDataReducerResult = {
  usage: PowerSensorUsage;
  accountTimezone: string;
  currentDayIndex: number;
};

const powerSensorItemToUsageDataReducer = (
  result: PowerSensorUsageDataReducerResult,
  item: PowerSensorDataItem,
): PowerSensorUsageDataReducerResult => {
  const dayjsDate = applyTimezone(item.t1, result.accountTimezone);
  const date = dayjsDate.startOf('day').toDate();
  const intervalIndex = dayJsToInterval(dayjsDate);
  const isNewDay =
    !result.usage.powersensor.A1.length || result.usage.powersensor.A1[result.currentDayIndex].date.getTime() !== date.getTime();

  if (isNewDay) {
    result.currentDayIndex += 1;

    const feedKeys = Object.keys(result.usage.powersensor) as PowerSensorUsageKey[];

    feedKeys.forEach(suffix => {
      result.usage.powersensor[suffix]?.push({
        date,
        intervals: [...EMPTY_INTERVALS],
        suffix,
      });
    });
  }
  const { exported, imported, solar, household } = item;
  if (!!result.usage.powersensor.B1) {
    result.usage.powersensor.B1[result.currentDayIndex].intervals[intervalIndex] = exported ?? 0;
  }
  result.usage.powersensor.E1[result.currentDayIndex].intervals[intervalIndex] = imported ?? 0;
  if (!!result.usage.powersensor.S1) {
    result.usage.powersensor.S1[result.currentDayIndex].intervals[intervalIndex] = solar ?? 0;
  }
  result.usage.powersensor.A1[result.currentDayIndex].intervals[intervalIndex] = household ?? 0;

  return result;
};

export const powerSensorDataToUsageData = (accountData: PowerSensorAccountData): PowerSensorUsage => {
  const hasSolar = accountData.data.some(item => !!item.solar);
  const feedKeys = hasSolar ? { B1: [], E1: [], S1: [], A1: [] } : { E1: [], A1: [] };
  const result: PowerSensorUsageDataReducerResult = accountData.data.reduce(powerSensorItemToUsageDataReducer, {
    usage: { powersensor: feedKeys },
    currentDayIndex: -1,
    accountTimezone: accountData.accountTimezone,
  } as PowerSensorUsageDataReducerResult);
  return result.usage;
};

export const getAllIntervalData = (usage: Usage): IntervalData[] => {
  return Object.values(usage).reduce((meterAcc: IntervalData[], meter: MeterData) => {
    return [
      ...meterAcc,
      ...Object.values(meter).reduce((feedAcc: IntervalData[], feed) => {
        return [...feedAcc, ...feed];
      }, []),
    ];
  }, []);
};

export const getConsumptionData = (usage: Usage): IntervalData[] => {
  return getAllIntervalData(usage).filter(({ suffix }: IntervalData) => suffix.startsWith('E'));
};

export const getGenerationData = (usage: Usage): IntervalData[] => {
  return getAllIntervalData(usage).filter(({ suffix }: IntervalData) => suffix.startsWith('B'));
};

export const getTotalConsumptionData = (usage: Usage): IntervalData[] => {
  return getAllIntervalData(usage).filter(({ suffix }: IntervalData) => suffix.startsWith('A'));
};

export const getTotalGenerationData = (usage: Usage): IntervalData[] => {
  return getAllIntervalData(usage).filter(({ suffix }: IntervalData) => suffix.startsWith('S'));
};

export const getIntervalPeaks = (consumptionData: IntervalData[]): MonthlyIntervalPeaks =>
  consumptionData.reduce((acc, current) => {
    const dayOfTheWeek = current.date.getDay();
    const month = getFormattedMonth(current.date, true);
    const currentMonth = acc[month];
    const currentMaxByDay = currentMonth?.maxKWByDayOfTheWeek;
    const currentMaxIntervals = currentMaxByDay?.[dayOfTheWeek];
    const maxIntervals: number[] = current.intervals.reduce((result: number[], kwh: number, index: number) => {
      const kWValue = kwh * 2; // KW is calculated by multiplying by 2 the kWh
      const maxInterval = Math.max(kWValue, currentMaxIntervals?.[index] ?? 0);
      return [...result, maxInterval];
    }, []);

    return {
      ...acc,
      [month]: {
        days: (currentMonth?.days ?? 0) + 1,
        maxKWByDayOfTheWeek: {
          ...(currentMaxByDay ?? {}),
          [dayOfTheWeek]: maxIntervals,
        },
      },
    };
  }, {} as MonthlyIntervalPeaks);

export const getMaxKW = (dayOfTheWeekIntervalPeaks: DayOfTheWeekIntervalPeaks) => {
  return Object.values(dayOfTheWeekIntervalPeaks).reduce((maxKW, peaksByInterval) => {
    const maxKWForDay = Math.max(...peaksByInterval);
    return Math.max(maxKW, maxKWForDay);
  }, 0);
};

export const getAbsolutePeaksByMonth = (intervalPeaks: MonthlyIntervalPeaks): MonthlyDemandPeaks => {
  const peaks = Object.keys(intervalPeaks).reduce((result: MonthlyDemandPeaks, month: string) => {
    const monthPeaks = intervalPeaks[month];
    return {
      ...result,
      [month]: {
        days: monthPeaks.days,
        maxKW: getMaxKW(monthPeaks.maxKWByDayOfTheWeek),
        rate: 0,
      },
    };
  }, {} as MonthlyDemandPeaks);
  return peaks;
};
