import styled from '@emotion/styled';
import { Button } from '@mui/material';
import PlansByPostcodeAndRetailer from 'aer/PlansByPostcodeAndRetailer';
import StepNavigation from 'components/StepNavigation';
import DashedDivider from 'components/common/DashedDivider';
import TariffFormToggle from 'components/form/TariffFormToggle';
import TariffCostCard from 'components/tariff/TariffCostCard';
import TariffDetailsModal from 'components/tariff/TariffDetailsModal';
import TariffTable from 'components/tariff/TariffTable';
import useTariffsWithPricingBreakdown from 'hooks/useTariffsWithPricingBreakdown';
import { useState } from 'react';
import { useStore } from 'store';
import { getTotal } from 'utils/transform';
import { Tariff } from 'utils/types';

const MaxWidthWrapper = styled.div`
  width: 100%;
  max-width: 40rem;
  align-self: center;
  justify-content: center;
  display: flex;
`;

const ComparisonTariffs = () => {
  const {
    comparisonTariffs,
    customerTariffs,
    customerTariffCostById,
    removeComparisonTariff,
    removeCustomerTariff,
    goToStep,
    previousStep,
  } = useStore();
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedTariff, setSelectedTariff] = useState<Tariff | undefined>(undefined);
  const orderedComparisonTariffs = useTariffsWithPricingBreakdown(comparisonTariffs);
  const showTable = !!orderedComparisonTariffs.length;

  const handleEdit = (tariff: Tariff) => {
    setIsFormOpen(false);
    setSelectedTariff(tariff);
    setIsFormOpen(true);
  };

  const handleView = (tariff: Tariff) => {
    setSelectedTariff(tariff);
    setIsModalOpen(true);
  };

  const handleClose = () => setIsModalOpen(false);

  const toggleForm = () => {
    setIsFormOpen(!isFormOpen);
    setSelectedTariff(undefined);
  };

  const defaultCustomerTariff = customerTariffs[0];

  return (
    <>
      {!defaultCustomerTariff && (
        <MaxWidthWrapper>
          <Button variant="contained" onClick={() => goToStep(1)} size="large" style={{ textTransform: 'none' }}>
            Add your tariff
          </Button>
        </MaxWidthWrapper>
      )}
      {customerTariffs.map((t, index) => {
        const breakdown = customerTariffCostById[t.id].breakdown;
        return (
          <TariffCostCard
            key={index}
            onEdit={() => handleEdit(t)}
            onRemove={index === 0 ? undefined : () => removeCustomerTariff(t)}
            title={t.name}
            total={getTotal(breakdown)}
            {...breakdown}
          />
        );
      })}
      {!!defaultCustomerTariff && <TariffFormToggle {...{ isFormOpen, selectedTariff, toggleForm }} />}
      <DashedDivider />
      <PlansByPostcodeAndRetailer />
      <DashedDivider />
      {showTable && <TariffTable onView={handleView} onRemove={removeComparisonTariff} tariffs={orderedComparisonTariffs} />}
      {showTable && <DashedDivider />}
      <StepNavigation
        nextButton={{ hidden: true }}
        previousButton={{
          onClick: () => (!defaultCustomerTariff ? goToStep(1) : previousStep()),
        }}
      />
      <TariffDetailsModal open={isModalOpen && !!selectedTariff} onClose={handleClose} tariff={selectedTariff!} />
    </>
  );
};

export default ComparisonTariffs;
