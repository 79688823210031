import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

type HeaderStep = {
  label: string;
  icon: JSX.Element;
  onClick: () => void;
  disabled?: boolean;
};

type HeaderProps = {
  activeStep: number;
  steps: HeaderStep[];
};

const StepContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 1.5rem;
`;

const Step = styled.div<{
  isActive: boolean;
  isCompleted: boolean;
  disabled: boolean;
}>`
  display: flex;
  align-items: center;
  color: ${({ theme, isActive, isCompleted, disabled }) =>
    disabled
      ? theme.palette.divider
      : isActive
        ? theme.palette.secondary.main
        : isCompleted
          ? theme.palette.primary.main
          : theme.palette.divider};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;

const StepIcon = styled.div<{
  isActive: boolean;
  isCompleted: boolean;
  disabled: boolean;
}>`
  color: ${({ theme, isActive, isCompleted, disabled }) =>
    disabled
      ? theme.palette.divider
      : isActive
        ? theme.palette.secondary.main
        : isCompleted
          ? theme.palette.primary.main
          : theme.palette.divider};
  margin-right: 8px;
`;

const StepLabel = styled.span<{
  isActive: boolean;
  isCompleted: boolean;
  disabled: boolean;
}>`
  color: inherit;
`;

const Dash = styled.span`
  display: inline-block;
  width: 1rem;
  height: 2px;
  background-color: ${({ theme }) => theme.palette.divider};
  margin: 0 1rem;
`;

const StepHeader: React.FC<HeaderProps> = ({ activeStep, steps }) => {
  const theme = useTheme();

  return (
    <StepContainer>
      {steps.map((step, index) => {
        const isActive = index === activeStep;
        const isCompleted = index < activeStep;

        return (
          <React.Fragment key={step.label}>
            <Step
              isActive={isActive}
              isCompleted={isCompleted}
              disabled={!!step.disabled}
              onClick={!step.disabled ? step.onClick : undefined}
              theme={theme}
            >
              <StepIcon isActive={isActive} isCompleted={isCompleted} disabled={!!step.disabled} theme={theme}>
                {step.icon}
              </StepIcon>
              <StepLabel isActive={isActive} isCompleted={isCompleted} disabled={!!step.disabled} theme={theme}>
                {step.label}
              </StepLabel>
            </Step>
            {index < steps.length - 1 && <Dash theme={theme} />}
          </React.Fragment>
        );
      })}
    </StepContainer>
  );
};

export default StepHeader;
