import dayjs from 'dayjs';
import { TariffRate } from 'utils/types';

const formatTariffDate = (dateString: string): string => {
  const dateWithYear = `${dateString}-2000`;
  const date = dayjs(dateWithYear, 'MM-DD-YYYY');
  return date.format('D MMMM');
};

type TariffRateGroupedByDate = {
  [key: string]: TariffRate[];
};

// Groups the tariff rates by same startDate and endDate
export const groupTariffRateByDates = (rates: TariffRate[]): TariffRateGroupedByDate => {
  return rates.reduce((acc, rate) => {
    const key = `${formatTariffDate(rate.startDate ?? '01-01')} - ${formatTariffDate(rate.endDate ?? '12-31')}`;
    return {
      ...acc,
      [key]: [...(acc[key] ?? []), rate],
    };
  }, {} as TariffRateGroupedByDate);
};
