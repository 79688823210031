import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton, Typography } from '@mui/material';
import StyledCard from 'components/common/card/StyledCard';
import StyledCardContent from 'components/common/card/StyledCardContent';

type TariffCostCardProps = {
  title?: string;
  total: number;
  generation: number;
  onEdit?: () => void;
  onRemove?: () => void;
};

const CostCard = styled(StyledCard)`
  align-self: center;
  max-width: 36rem;
  width: 100%;
  margin-bottom: 1rem;
`;

const Row = styled(StyledCardContent)`
  display: flex;
`;

const FlexBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex: 1;
`;

const ActionButton = styled(Button)`
  height: fit-content;
  color: ${({ theme }) => theme.palette.text.primary};
  border-color: ${({ theme }) => theme.palette.grey[100]};
  text-transform: none;
  font-weight: bold;
  border-radius: 10px;
  padding: 0.25rem 1.25rem;
`;

const TariffCostCard: React.FC<TariffCostCardProps> = ({ onEdit, onRemove, generation, title, total }) => (
  <CostCard>
    <Row>
      <FlexBox>
        <Typography variant="body1">{title}</Typography>
        <Typography variant="h4" fontWeight="bold" color={theme => theme.palette.text.primary}>
          {`$${Math.round(total)} /Year`}
        </Typography>
        {!!generation && <Typography variant="body1">{`Solar Credit: $${Math.round(generation)}`}</Typography>}
      </FlexBox>
      {onEdit && (
        <ActionButton onClick={onEdit} variant="outlined">
          Edit
        </ActionButton>
      )}
      {onRemove && (
        <IconButton aria-label="delete" size="small" onClick={onRemove} sx={{ height: 'fit-content', marginLeft: '0.75rem' }}>
          <CloseIcon fontSize="small" fill="textSecondary" />
        </IconButton>
      )}
    </Row>
  </CostCard>
);

export default TariffCostCard;
