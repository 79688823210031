import { InputAdornment, MenuItem, Select, SelectChangeEvent, TextField, TextFieldProps } from '@mui/material';
import { ChangeEvent } from 'react';

type TextFieldWithTypeOptionsProps = {
  adornmentValue?: string;
  adornmentOptions?: string[];
  onAdornmentChange?: (type: string) => void;
  onValueChange?: (value: string) => void;
} & TextFieldProps;

const TextFieldWithTypeOptions: React.FC<TextFieldWithTypeOptionsProps> = ({
  adornmentValue,
  adornmentOptions = [],
  onChange,
  onValueChange,
  onAdornmentChange,
  id,
  ...props
}) => {
  const onSelectChange = (event: SelectChangeEvent) => onAdornmentChange?.(event.target.value);
  const onInputChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
    onChange || onValueChange?.(event.target.value);
  const endAdornment: JSX.Element | undefined = adornmentOptions.length ? (
    <InputAdornment position="end">
      <Select
        name={`${id}-adornment`}
        disableUnderline={true}
        disabled={adornmentOptions.length < 2}
        onChange={onSelectChange}
        value={adornmentValue}
        variant="standard"
      >
        {adornmentOptions.map(t => (
          <MenuItem key={t} value={t}>
            {t}
          </MenuItem>
        ))}
      </Select>
    </InputAdornment>
  ) : undefined;
  return <TextField InputProps={{ endAdornment }} onChange={onInputChange} {...props} style={{ width: '14rem' }} />;
};

export default TextFieldWithTypeOptions;
