import styled from '@emotion/styled';
import { FormControl, Typography } from '@mui/material';
import { ReactNode } from 'react';

type InputContainerProps = {
  children?: ReactNode;
  description?: string;
  helperText?: string;
  title?: string;
  displayFlex?: boolean;
};

const InputContainerWrapper = styled(FormControl)`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 2rem;
  max-width: 40rem;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TitleDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ChildrenWrapper = styled.div<{ error?: boolean }>`
  margin: 0.5rem 0;
`;

const InputContainer: React.FC<InputContainerProps> = ({ children, description, helperText, title, displayFlex }) => {
  const ContentWrapper = displayFlex ? FlexContainer : 'div';

  return (
    <InputContainerWrapper>
      <ContentWrapper>
        <TitleDescriptionWrapper>
          {title && <Typography variant="h6">{title}</Typography>}
          {description && <Typography variant="subtitle1">{description}</Typography>}
        </TitleDescriptionWrapper>
        {children && <ChildrenWrapper>{children}</ChildrenWrapper>}
      </ContentWrapper>
      {helperText && <Typography variant="subtitle2">{helperText}</Typography>}
    </InputContainerWrapper>
  );
};

export default InputContainer;
