import { AERPlanFeeType } from 'services/aer/types';
import { useStore } from 'store';
import { getTotal } from 'utils/transform';
import { Tariff, TariffWithBreakdownAndTotal } from 'utils/types';

const useTariffsWithPricingBreakdown = (tariffs: Tariff[], ordered = true): TariffWithBreakdownAndTotal[] => {
  const { tariffCostsById } = useStore();

  const toTariffWithPricingBreakdown = (tariff: Tariff) => {
    const breakdown = tariffCostsById[tariff.id];
    let total = getTotal(breakdown);
    const membershipFee = tariff.fees?.find(fee => fee.type === AERPlanFeeType.MEMBERSHIP);
    if (membershipFee) {
      total += Number(membershipFee.amount ?? 0);
      total += total * Number(membershipFee.rate ?? 0);
    }
    return {
      tariff,
      breakdown,
      total,
    };
  };

  const tariffsWithBreakdown = tariffs.map(toTariffWithPricingBreakdown);
  return ordered ? tariffsWithBreakdown.sort((a, b) => a.total - b.total) : tariffsWithBreakdown;
};

export default useTariffsWithPricingBreakdown;
