import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Tooltip, Typography } from '@mui/material';
import { ALL_INTERVAL_INDEXES } from 'utils/constants';
import { intervalPositionToHourString } from 'utils/timeHelper';
import { PeriodType, PieInterval, TariffFormRate } from 'utils/types';

type ClockBarRatesProps = {
  rates: TariffFormRate[];
  highlightUnfulfilled?: boolean;
};

const StyledBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;

const StyledChip = styled.div<{ stroke?: string; fill: string }>`
  background-color: ${({ fill }) => fill};
  color: ${({ fill }) => fill};
  border: 1px solid ${({ stroke }) => stroke ?? 'currentColor'};
  height: 1.5rem;
  width: 100%;
  border-radius: 3px;
  margin-right: 2px;
  &:last-child {
    margin-right: 0;
  }
`;

const ClockBarRates: React.FC<ClockBarRatesProps> = ({ rates, highlightUnfulfilled }) => {
  const theme = useTheme();
  const intervals = rates.reduce(
    (acc: PieInterval[], rate: TariffFormRate) => {
      rate.period.intervalsIndex.forEach(index => {
        acc[index] = {
          ...acc[index],
          rate: rate.rate,
          type: rate.type,
          periodType: rate.period.type,
        };
      });
      return acc;
    },
    ALL_INTERVAL_INDEXES.map(index => ({
      index,
      value: 30,
    })),
  );
  const colorByType = (type: PeriodType) => {
    switch (type) {
      case 'peak':
        return theme.palette.primary.main;
      case 'off_peak':
        return '#C2CBE7';
      case 'shoulder':
        return theme.palette.secondary.light;
    }
  };

  return (
    <>
      <StyledBarContainer>
        {ALL_INTERVAL_INDEXES.map(index => {
          const interval = intervals[index];
          const stroke = !interval.rate && highlightUnfulfilled ? theme.palette.error.main : undefined;
          const fill = interval.periodType ? colorByType(interval.periodType) : theme.palette.grey[200];
          const tooltipText = `${intervalPositionToHourString(index, true)} - ${intervalPositionToHourString(index, false)}`;
          return (
            <Tooltip title={tooltipText} key={index}>
              <StyledChip {...{ stroke, fill }} />
            </Tooltip>
          );
        })}
      </StyledBarContainer>
      <StyledBarContainer>
        {[...ALL_INTERVAL_INDEXES, 0].map((index, i) =>
          index % 6 === 0 ? (
            <Typography variant="caption" fontWeight={index % 24 === 0 ? 'bold' : 'normal'} key={i}>
              {intervalPositionToHourString(index, true, true)}
            </Typography>
          ) : null,
        )}
      </StyledBarContainer>
    </>
  );
};

export default ClockBarRates;
