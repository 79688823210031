import styled from '@emotion/styled';
import { Card } from '@mui/material';

const StyledCard = styled(Card)`
  box-shadow:
    0px 12px 24px -4px ${({ theme }) => theme.palette.grey[100]},
    0px 0px 2px 0px ${({ theme }) => theme.palette.grey[100]};
  border-radius: 1rem;
`;

export default StyledCard;
