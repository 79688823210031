import styled from '@emotion/styled';
import AddIcon from '@mui/icons-material/Add';
import { Fab } from '@mui/material';

type ClickableProps = { onClick: () => void };

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const FloatingAddButton: React.FC<ClickableProps> = ({ onClick }) => (
  <CenteredContainer>
    <Fab color="primary" aria-label="add" onClick={onClick}>
      <AddIcon />
    </Fab>
  </CenteredContainer>
);

export default FloatingAddButton;
