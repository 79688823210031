import InputContainer from 'components/form/fields/InputContainer';
import ToggleSelectionButtonGroup from 'components/ToggleSelectionButtonGroup';
import { CONSUMPTION_TYPE_OPTIONS } from 'utils/constants';

type ToggleConsumptionTariffTypeProps = {
  value?: string;
  onChange: (value: string) => void;
};
const ToggleConsumptionTariffType: React.FC<ToggleConsumptionTariffTypeProps> = props => (
  <>
    <InputContainer
      title="Tariff Type"
      description="What tariff applies to your electricity bill?"
      helperText="You can find tariff information on your energy bill. Some providers call their single tariff an 'Anytime rate'"
    />
    <ToggleSelectionButtonGroup options={CONSUMPTION_TYPE_OPTIONS} {...props} />
  </>
);

export default ToggleConsumptionTariffType;
