import { useTheme } from '@emotion/react';
import { Bar, BarChart, Label, LabelList, LabelProps, Legend, ReferenceLine, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { getRandomColors } from 'utils/randomColors';
import { TariffFormRate } from 'utils/types';

type BlockTariffBarProps = {
  referenceLine: number;
  rates: TariffFormRate[];
  blockDefaultSize?: number;
};

const renderCustomizedLabel = (value: string) => (props: LabelProps) => {
  const { content, ...rest } = props;

  return <Label {...rest} value={value} fontSize="12" fill="#FFFFFF" fontWeight="Bold" />;
};

const roundToHundredUp = (value: number) => Math.ceil(value / 100) * 100;

const BlockTariffBar: React.FC<BlockTariffBarProps> = ({ referenceLine: referenceLine, rates }) => {
  const theme = useTheme();
  const colors = getRandomColors(rates.length);
  const bar = rates.reduce(
    (acc, current, i, all) => {
      const previous: TariffFormRate | undefined = all[i - 1];
      const isLast = i === all.length - 1;
      const from = previous?.threshold ?? '0';
      const to = current.threshold.length ? current.threshold : '∞';
      const key = `${from} - ${to} kWh`;
      const lastBlocEstimatedSize = referenceLine - Number(from);
      const blockDefaultSize = Number(from) * 0.25; // 25% of the previous block
      const lastBlocSize = roundToHundredUp(Math.max(lastBlocEstimatedSize + 100, blockDefaultSize));
      const value = isLast ? lastBlocSize : Number(current.threshold) - Number(from);
      return {
        ...acc,
        [key]: value,
      };
    },
    {} as Record<string, number>,
  );
  const total = Math.round(referenceLine);
  return (
    <ResponsiveContainer height={96} width="100%">
      <BarChart layout="vertical" data={[bar]}>
        <XAxis hide type="number" />
        <YAxis hide type="category" stroke="transparent" fontSize="12" />
        <Legend />
        {Object.entries(bar).map(([key], index) => (
          <Bar dataKey={key} fill={colors[index]} stackId="a" key={key}>
            <LabelList
              dataKey={key}
              position="center"
              content={renderCustomizedLabel(`${rates[index].rate.length ? rates[index].rate : '-'} ${rates[index].type}`)}
            />
          </Bar>
        ))}
        <ReferenceLine x={total} stroke={theme.palette.text.primary} strokeWidth={3} strokeLinecap="round" type="monotone" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BlockTariffBar;
