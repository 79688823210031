import { Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { SxProps, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  gap: '0.5rem',
  '& .MuiToggleButtonGroup-grouped': {
    margin: '-1rem 0 2rem 0',
    border: '1px solid',
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderTop: '1px solid',
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

const StyledToggleButton = styled(ToggleButton)`
  text-transform: none;
  width: 12rem;
`;

type Option<T> = {
  value: T;
  label: string;
  disabled?: boolean;
};

type ToggleSelectionButtonGroupProps<T> = {
  value?: T;
  options: Option<T>[];
  onChange?: (value: T) => void;
  orientation?: 'horizontal' | 'vertical';
  sx?: SxProps<Theme>;
};

function OptionToggleButton<T extends NonNullable<unknown>>({ label, ...props }: Readonly<Option<T>>) {
  return (
    <StyledToggleButton aria-label={label} key={label} {...props}>
      <Typography>{label}</Typography>
    </StyledToggleButton>
  );
}

function ToggleSelectionButtonGroup<T extends NonNullable<unknown>>({
  onChange,
  options,
  value,
  orientation = 'horizontal',
  sx,
}: Readonly<ToggleSelectionButtonGroupProps<T>>) {
  const onValueChange = (_event: React.MouseEvent<HTMLElement>, nextValue: T) => {
    if (nextValue !== null) onChange?.(nextValue);
  };
  return (
    <StyledToggleButtonGroup color="primary" orientation={orientation} value={value} exclusive onChange={onValueChange} sx={sx}>
      {options.map(OptionToggleButton)}
    </StyledToggleButtonGroup>
  );
}

export default ToggleSelectionButtonGroup;
