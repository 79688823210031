import styled from '@emotion/styled';
import Visible from 'components/Visible';
import BlockBasedTariff from 'components/form/fields/BlockBasedTariff';
import ConsumptionSingleTariffRate from 'components/form/fields/ConsumptionSingleTariff';
import DemandTariffRate from 'components/form/fields/DemandTariffRate';
import GenerationSingleTariffRate from 'components/form/fields/GenerationSingleTariff';
import NameInput from 'components/form/fields/NameInput';
import SecondaryTariffs from 'components/form/fields/SecondaryTariff';
import StartDateTariff from 'components/form/fields/StartDateTariff';
import SupplyCharge from 'components/form/fields/SupplyCharge';
import TimeBasedTariff from 'components/form/fields/TimeBasedTariff';
import ToggleConsumptionTariffType from 'components/form/fields/ToggleConsumptionTariffType';
import ToggleGenerationTariffType from 'components/form/fields/ToggleGenerationTariffType';
import { v4 as uuidv4 } from 'uuid';
import dayjs, { Dayjs } from 'dayjs';
import { FormikErrors, useFormik } from 'formik';
import { ReactNode, useEffect, useMemo } from 'react';
import { useStore } from 'store';
import { INITIAL_BLOCK_TARIFF_FORM_RATES, SINGLE_PERIOD, YEAR_DURATION } from 'utils/constants';
import { hasAllPeriods } from 'utils/timeHelper';
import { formValuesToTariff, tariffFormRateToTariffRate, tariffToFormValues } from 'utils/transform';
import {
  ControlledLoadFormRate,
  SupplyChargeType,
  Tariff,
  TariffCycle,
  TariffFormRate,
  TariffFormValues,
  TariffRateType,
  TariffType,
} from 'utils/types';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 40rem;
`;

type TariffFormProps = {
  actions: ReactNode;
  onSubmit: (tariff: Tariff) => void;
  tariff?: Tariff;
  isCompare?: boolean;
};

const getRateErrors = ({ type, rates }: { type: TariffType; rates: TariffFormRate[] }): string | undefined => {
  if (rates.length === 0) {
    return 'This field is mandatory';
  }
  if (rates.some(r => !r.rate.length || Number(r.rate) <= 0)) {
    return 'Rate is invalid.';
  }
  switch (type) {
    case 'block':
      const { hasError } = rates.slice(0, -1).reduce(
        (acc, r) => {
          const hasError = !r.threshold.length || Number(r.threshold) <= acc.previousThreshold;
          return { previousThreshold: Number(r.threshold), hasError };
        },
        { previousThreshold: 0, hasError: false },
      );
      if (hasError) {
        return 'Threshold is invalid.';
      }
      break;
    case 'demand':
    case 'time':
      if (!hasAllPeriods(rates.map(tariffFormRateToTariffRate).map(r => r.period))) {
        return 'Not all periods are covered';
      }
      break;
    default:
      break;
  }
  return undefined;
};

const TariffForm: React.FC<TariffFormProps> = ({ actions, isCompare = false, onSubmit: onSubmitCallback, tariff }) => {
  const { generationData, consumptionData, controlledLoadData } = useStore();
  const dataStartDate: Date = consumptionData[0].date;
  const hasSolar: boolean = !!generationData.length;
  const numberOfSecondaryTariffs = controlledLoadData.length;
  const hasSecondaryTariffs = numberOfSecondaryTariffs > 0;
  // NOTE (pdiego): In case data is not annual, we need to divide by the days included in data and multiply by year duration.
  const yearlyGenerationData = useMemo(
    () =>
      Math.round(
        (generationData.reduce((acc, { intervals }) => acc + intervals.reduce((acc, interval) => acc + interval, 0), 0) /
          consumptionData.length) *
          YEAR_DURATION,
      ),
    [generationData],
  );
  const yearlyConsumptionData = useMemo(
    () =>
      Math.round(
        (consumptionData.reduce((acc, { intervals }) => acc + intervals.reduce((acc, interval) => acc + interval, 0), 0) /
          consumptionData.length) *
          YEAR_DURATION,
      ),
    [consumptionData],
  );
  const initialValues: TariffFormValues = tariffToFormValues(
    tariff,
    hasSolar,
    dayjs(dataStartDate),
    isCompare,
    numberOfSecondaryTariffs,
  );

  const validate = (values: TariffFormValues): void | object | Promise<FormikErrors<TariffFormValues>> => {
    const errors: FormikErrors<TariffFormValues> = {};
    if (isCompare && !values.name) {
      errors.name = 'This field is mandatory';
    }
    if (!values.startDate) {
      errors.startDate = 'This field is mandatory';
    }
    if (!values.startDate?.isValid()) {
      errors.startDate = 'Invalid date';
    }
    if (!values.supplyChargeRate) {
      errors.supplyChargeRate = 'This field is mandatory';
    }
    if (values.consumptionTariffType === 'demand') {
      if (!values.demandRates.length) {
        errors.demandRates = 'This field is mandatory';
      } else if (!values.consumptionRates.some(r => r.period.type === 'peak')) {
        errors.demandRates = 'You must have a peak period in your rates above.';
      }
    }
    const consumptionRatesError = getRateErrors({
      type: values.consumptionTariffType,
      rates: values.consumptionRates,
    });
    if (consumptionRatesError) {
      errors.consumptionRates = consumptionRatesError;
    }
    if (!!controlledLoadData.length) {
      const controlledLoadErrors = values.controlledLoadRates.map(r =>
        Number(r.rate) <= 0 || Number(r.supplyChargeRate) <= 0 ? 'Secondary rate is invalid' : '',
      );
      if (controlledLoadErrors.some(cl => !!cl.length)) {
        errors.controlledLoadRates = controlledLoadErrors;
      }
    }
    if (values.hasSolar) {
      const generationRatesError = getRateErrors({
        type: values.generationTariffType,
        rates: values.generationRates,
      });
      if (generationRatesError) {
        errors.generationRates = generationRatesError;
      }
    }
    return errors;
  };

  const onSubmit = (values: TariffFormValues) => {
    const tariffInput = formValuesToTariff(values);
    onSubmitCallback(tariffInput);
  };

  const { errors, handleSubmit, setFieldValue, touched, values, setValues } = useFormik({
    initialValues,
    onSubmit,
    validate,
    validateOnChange: true,
  });

  useEffect(() => {
    const updatedValues = tariffToFormValues(tariff, hasSolar, dayjs(dataStartDate), isCompare, numberOfSecondaryTariffs);
    setValues(updatedValues);
  }, [tariff, hasSolar, dataStartDate, isCompare, setValues, controlledLoadData.length]);

  const hasSupplyRate = !!values.supplyChargeRate;
  const isBlockConsumptionTariff = values.consumptionTariffType === 'block';
  const isBlockGenerationTariff = values.generationTariffType === 'block';
  const isDemandConsumptionTariff = values.consumptionTariffType === 'demand';
  const isSingleConsumptionTariff = values.consumptionTariffType === 'single';
  const isSingleGenerationTariff = values.generationTariffType === 'single';
  const isTimeBasedConsumptionTariff = values.consumptionTariffType === 'time';
  const isTimeBasedGenerationTariff = values.generationTariffType === 'time';
  const hasConsumptionRates = !!values.consumptionRates.length;
  const hasGenerationRates = !!values.generationRates.length;
  const nameError: string | undefined = touched.name ? errors.name : undefined;
  const supplyChargeError: string | undefined = touched.supplyChargeRate ? errors.supplyChargeRate : undefined;
  const startDateError: string | undefined = touched.startDate ? errors.startDate : undefined;
  const hasConsumptionRatesError: boolean = !!touched.consumptionRates && !!errors.consumptionRates?.length;
  const consumptionRatesError: string | undefined = hasConsumptionRatesError ? errors.consumptionRates + '' : undefined;
  const hasGenerationRatesError: boolean = !!touched.generationRates && !!errors.generationRates?.length;
  const generationRatesError: string | undefined = hasGenerationRatesError ? errors.generationRates + '' : undefined;
  const demandRateError: string | undefined = touched.demandRates ? errors.demandRates + '' : undefined;
  const controlledLoadErrors: string[] | undefined = touched.controlledLoadRates
    ? (errors.controlledLoadRates as string[])
    : undefined;

  const onNameChange = (value: string) => setFieldValue('name', value);
  const onStartDateChange = (value: Dayjs | null) => setFieldValue('startDate', value);
  const onSupplyRateTypeChange = (value: string) => setFieldValue('supplyChargeType', value as SupplyChargeType);
  const onSupplyChargeRateChange = (value: string) => setFieldValue('supplyChargeRate', value);
  const onConsumptionCycleChange = (cycle: TariffCycle) => setFieldValue('consumptionTariffCycle', cycle);
  const onGenerationCycleChange = (cycle: TariffCycle) => setFieldValue('generationTariffCycle', cycle);

  // CONSUMPTION
  const onConsumptionTariffTypeChange = (value: string) => {
    const tariffType = value as TariffType;
    setFieldValue('consumptionRates', tariffType === 'block' ? INITIAL_BLOCK_TARIFF_FORM_RATES : []);
    setFieldValue('consumptionTariffType', value as TariffType);
  };
  const onConsumptionRateTypeChange = (value: string) => {
    const tariffRateType = value as TariffRateType;
    if (isSingleConsumptionTariff && hasConsumptionRates) {
      setFieldValue('consumptionRates', [{ ...values.consumptionRates[0], type: tariffRateType }]);
    }
    setFieldValue('consumptionRateType', tariffRateType);
  };
  const onConsumptionSingleTariffRateChange = (value: string) => {
    if (!value) {
      setFieldValue('consumptionRates', []);
      return;
    }
    const consumptionTariffRate: TariffFormRate = {
      id: uuidv4(),
      threshold: '',
      period: SINGLE_PERIOD,
      rate: value,
      type: values.consumptionRateType,
    };
    setFieldValue('consumptionRates', [consumptionTariffRate]);
  };
  const onConsumptionRatesChange = (value: TariffFormRate[]) => {
    setFieldValue('consumptionRates', value);
  };
  const onControlledLoadRatedChange = (value: ControlledLoadFormRate[]) => {
    setFieldValue('controlledLoadRates', value);
  };
  const onDemandRateTypeChange = (value: string) => setFieldValue('demandRateType', value as TariffRateType);
  const onSingleDemandRateChange = (value: string) => {
    if (!value) {
      setFieldValue('demandRates', []);
      return;
    }
    const demandRate: TariffFormRate = {
      id: uuidv4(),
      threshold: '',
      period: SINGLE_PERIOD, // FIXME: Change this to peak period
      rate: value,
      type: values.demandRateType,
    };
    setFieldValue('demandRates', [demandRate]);
  };

  // GENERATION
  const onGenerationTariffTypeChange = (value: string) => {
    const tariffType = value as TariffType;
    setFieldValue('generationRates', tariffType === 'block' ? INITIAL_BLOCK_TARIFF_FORM_RATES : []);
    setFieldValue('generationTariffType', value as TariffType);
  };
  const onGenerationRateTypeChange = (value: string) => {
    const tariffRateType = value as TariffRateType;
    if (isSingleGenerationTariff && hasGenerationRates) {
      setFieldValue('generationRates', [{ ...values.generationRates[0], type: tariffRateType }]);
    }
    setFieldValue('generationRateType', tariffRateType);
  };
  const onGenerationSingleTariffRateChange = (value: string) => {
    if (!value) {
      setFieldValue('generationRates', []);
      return;
    }
    const generationTariffRate: TariffFormRate = {
      id: uuidv4(),
      threshold: '',
      period: SINGLE_PERIOD,
      rate: value,
      type: values.generationRateType,
    };
    setFieldValue('generationRates', [generationTariffRate]);
  };
  const onGenerationRatesChange = (value: TariffFormRate[]) => {
    setFieldValue('generationRates', value);
  };

  return (
    <form onSubmit={handleSubmit} id="customer-tariff-form">
      <Wrapper>
        <Visible on={isCompare}>
          <NameInput error={!!nameError} helperText={nameError} onValueChange={onNameChange} value={values.name} />
        </Visible>
        <Visible on={!isCompare || !!values.name}>
          <div style={{ margin: '2.5rem 0 -1rem 0' }}>
            <StartDateTariff value={values.startDate ?? dayjs(dataStartDate)} onChange={onStartDateChange} error={startDateError} />
          </div>
          <SupplyCharge
            adornmentValue={values.supplyChargeType}
            error={!!supplyChargeError}
            helperText={supplyChargeError}
            onAdornmentChange={onSupplyRateTypeChange}
            onValueChange={onSupplyChargeRateChange}
            value={values.supplyChargeRate}
          />
          <Visible on={hasSupplyRate}>
            <ToggleConsumptionTariffType onChange={onConsumptionTariffTypeChange} value={values.consumptionTariffType} />
            <Visible on={isSingleConsumptionTariff}>
              <ConsumptionSingleTariffRate
                adornmentValue={values.consumptionRateType}
                error={hasConsumptionRatesError}
                helperText={consumptionRatesError}
                onAdornmentChange={onConsumptionRateTypeChange}
                onValueChange={onConsumptionSingleTariffRateChange}
                value={values.consumptionRates?.[0]?.rate ?? ''}
              />
            </Visible>
            <Visible on={isBlockConsumptionTariff}>
              <BlockBasedTariff
                cycle={values.consumptionTariffCycle}
                error={consumptionRatesError}
                estimatedAnnualTotalKwh={yearlyConsumptionData}
                values={values.consumptionRates}
                onChange={onConsumptionRatesChange}
                onCycleChange={onConsumptionCycleChange}
              />
            </Visible>
            <Visible on={isTimeBasedConsumptionTariff || isDemandConsumptionTariff}>
              <TimeBasedTariff
                error={consumptionRatesError}
                onChange={onConsumptionRatesChange}
                title={isDemandConsumptionTariff ? 'Demand' : 'Time-of-use'}
                value={values.consumptionRates}
              />
            </Visible>
            <Visible on={isDemandConsumptionTariff}>
              <DemandTariffRate
                adornmentValue={values.demandRateType}
                error={!!demandRateError}
                helperText={demandRateError}
                onAdornmentChange={onDemandRateTypeChange}
                onValueChange={onSingleDemandRateChange}
                value={values.demandRates[0]?.rate ?? ''}
              />
            </Visible>
            <Visible on={hasConsumptionRates && hasSecondaryTariffs}>
              <SecondaryTariffs
                value={values.controlledLoadRates}
                onValueChange={onControlledLoadRatedChange}
                error={controlledLoadErrors}
              />
            </Visible>
            <Visible on={hasConsumptionRates && values.hasSolar}>
              <ToggleGenerationTariffType onChange={onGenerationTariffTypeChange} value={values.generationTariffType} />
              <Visible on={isSingleGenerationTariff}>
                <GenerationSingleTariffRate
                  adornmentValue={values.generationRateType}
                  error={hasGenerationRatesError}
                  helperText={generationRatesError}
                  onAdornmentChange={onGenerationRateTypeChange}
                  onValueChange={onGenerationSingleTariffRateChange}
                  value={values.generationRates[0]?.rate ?? ''}
                />
              </Visible>
              <Visible on={isBlockGenerationTariff}>
                <BlockBasedTariff
                  cycle={values.generationTariffCycle}
                  error={generationRatesError}
                  estimatedAnnualTotalKwh={yearlyGenerationData}
                  values={values.generationRates}
                  onChange={onGenerationRatesChange}
                  onCycleChange={onGenerationCycleChange}
                  isExport
                />
              </Visible>
              <Visible on={isTimeBasedGenerationTariff}>
                <TimeBasedTariff
                  error={generationRatesError}
                  onChange={onGenerationRatesChange}
                  title="Time-of-export"
                  value={values.generationRates}
                />
              </Visible>
            </Visible>
          </Visible>
        </Visible>
      </Wrapper>
      {actions}
    </form>
  );
};

export default TariffForm;
