import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';

type QueryProperties<T> = {
  defaultErrorMessage?: string;
  onCalled?: () => void;
  onError?: (e: AxiosError) => void;
  onSuccess?: (data: T) => void;
  skip?: boolean;
};

type QueryResult<T> = {
  called: boolean;
  data?: T;
  error?: string;
  loading: boolean;
  refetch: (delay?: number) => void;
};

const UNKNOWN_ERROR = 'Error, please try again';

const useQuery = <T, U>(query: (params: U) => Promise<T>, params: U, properties: QueryProperties<T> = {}): QueryResult<T> => {
  const { defaultErrorMessage = UNKNOWN_ERROR, onCalled, onError, onSuccess, skip = false } = properties;
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<T | undefined>();
  const [called, setCalled] = useState<boolean>(false);

  const initialize = (): void => {
    onCalled?.();
    setCalled(true);
    setLoading(true);
    setError(undefined);
  };

  const handleError = (e: AxiosError) => {
    setLoading(false);
    setData(undefined);
    const message = e.message || defaultErrorMessage;
    setError(message);
    onError?.(e);
  };

  const handleSuccess = (rs: T) => {
    setData(rs);
    setLoading(false);
    onSuccess?.(rs);
  };

  const refetch = useCallback(() => {
    initialize();
    query(params).then(handleSuccess).catch(handleError);
  }, [params]);

  useEffect(() => {
    if (!skip) refetch();
  }, [refetch]);

  return { called, data, error, loading, refetch };
};

export default useQuery;
