import styled from '@emotion/styled';
import { Container, Grid, Link, Typography } from '@mui/material';
import PowerSensorLogo from 'assets/png/powersensor-logo-white.png';

const FooterWrapper = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  padding: theme.spacing(4),
}));

const Footer = () => {
  return (
    <footer>
      <FooterWrapper container sx={{ mt: '60px' }}>
        <Grid item xs={12} md={12}>
          <Container>
            <center>
              <a href="/">
                <img src={PowerSensorLogo} alt="Powersensor logo" width={200} />
              </a>{' '}
              <Typography variant="subtitle1">&copy;2024 Powersensor. All rights reserved.</Typography>
            </center>
          </Container>
        </Grid>
        <Grid item xs={12}>
          <center>
            <Typography variant="subtitle2">
              <Link sx={{ color: 'white' }} href="https://www.powersensor.com.au/terms-conditions">
                Terms and Conditions
              </Link>
              <span>&nbsp;&nbsp;&nbsp;</span>
              <Link sx={{ color: 'white' }} href="https://www.powersensor.com.au/privacy-policy">
                Privacy Policy
              </Link>
            </Typography>
          </center>
        </Grid>
      </FooterWrapper>
    </footer>
  );
};

export default Footer;
