import styled from '@emotion/styled';
import { Modal } from '@mui/material';
import ModalContainer from 'components/common/ModalContainer';
import TariffDetails from 'components/tariff/TariffDetails';
import { Tariff } from 'utils/types';

type TariffDetailsModalProps = {
  open: boolean;
  onClose: () => void;
  tariff: Tariff;
};

const StyledModalContainer = styled(ModalContainer)`
  width: unset;
  min-width: 24rem;
  max-height: 90%;
  max-width: 90%;
`;

const TariffDetailsModal: React.FC<TariffDetailsModalProps> = ({ open, onClose, tariff }) => {
  return (
    <Modal {...{ onClose, open }}>
      <StyledModalContainer>
        <TariffDetails {...{ tariff }} />
      </StyledModalContainer>
    </Modal>
  );
};

export default TariffDetailsModal;
