import InputContainer from 'components/form/fields/InputContainer';
import TextFieldWithTypeOptions from 'components/TextFieldWithTypeOptions';
import { TARIFF_RATE_TYPE_OPTIONS } from 'utils/constants';

type ConsumptionSingleTariffRateProps = React.ComponentProps<typeof TextFieldWithTypeOptions>;
const ConsumptionSingleTariffRate: React.FC<ConsumptionSingleTariffRateProps> = props => (
  <InputContainer
    title="Tariff Rate"
    description="What tariff rate are you charged?"
    helperText="This may be referred as 'Consumption rate', 'Anytime usage' or 'General usage'."
  >
    <TextFieldWithTypeOptions id="consumption-tariff-rate" adornmentOptions={TARIFF_RATE_TYPE_OPTIONS} type="number" {...props} />
  </InputContainer>
);

export default ConsumptionSingleTariffRate;
