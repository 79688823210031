import styled from '@emotion/styled';
import { Button, Modal, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import TextFieldWithTypeOptions from 'components/TextFieldWithTypeOptions';
import ModalContainer from 'components/common/ModalContainer';
import dayjs, { Dayjs } from 'dayjs';
import { FormikErrors, FormikHelpers, useFormik } from 'formik';
import { ReactNode } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ALL_INTERVAL_INDEXES, TARIFF_RATE_TYPE_OPTIONS } from 'utils/constants';
import { getIntervalsFromDates, periodTypeToString, splitHourString } from 'utils/timeHelper';
import { PeriodType, TariffFormRate, TariffRateType } from 'utils/types';

const StyledModalContent = styled.div`
  flex: 1;
  margin-bottom: 1rem;
`;

const StyledSubtitle = styled(Typography)`
  margin-top: 1rem;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title: React.FC<{ children: ReactNode }> = ({ children }) => (
  <Typography fontWeight="bold" variant="h5">
    {children}
  </Typography>
);

const Subtitle: React.FC<{ children: ReactNode }> = ({ children }) => (
  <StyledSubtitle variant="subtitle1">{children}</StyledSubtitle>
);

const StyledTimePicker = styled(TimePicker)`
  margin-top: 1rem;
`;

const StyledButton = styled(Button)`
  text-transform: none;
  width: 7rem;
`;

type ModalProps = {
  dayIndexes: number[];
  disabledIntervalIndexes: number[];
  isOpen: boolean;
  tariffRateAction: (tariffRate: TariffFormRate) => void;
  onClose: () => void;
  title: string;
  periodType: PeriodType;
  initial: TariffFormRate | null;
};

type FormValues = {
  id?: string;
  periodType: PeriodType;
  rate: string;
  rateType: TariffRateType;
  period: {
    from: Dayjs;
    to: Dayjs;
  };
};

const titleMappings: { [key: string]: string } = {
  'Time-of-use': 'Time of Use',
};

const getTitle = (title: string): string => titleMappings[title] || title;

const TariffRateModal: React.FC<ModalProps> = ({
  dayIndexes,
  disabledIntervalIndexes,
  isOpen,
  tariffRateAction,
  onClose,
  title,
  periodType,
  initial = null,
}) => {
  const period = splitHourString(initial?.period.intervalsIndex || null);
  const initialValues: FormValues = {
    id: initial?.id,
    rate: initial?.rate || '',
    periodType,
    rateType: initial?.type || '¢/kWh',
    period: {
      from: period && period[0] ? dayjs(period[0], 'h:mma') : dayjs('2020-01-01 00:00'),
      to: period && period[1] ? dayjs(period[1], 'h:mma') : dayjs('2020-01-01 00:00'),
    },
  };

  const onSubmit = (values: FormValues, { resetForm }: FormikHelpers<FormValues>) => {
    const tariffRate: TariffFormRate = {
      id: values?.id || uuidv4(),
      rate: values.rate,
      type: values.rateType,
      period: {
        type: periodType,
        daysOfTheWeekIndex: dayIndexes,
        intervalsIndex: getIntervalsFromDates(values.period.from, values.period.to),
      },
      threshold: '',
    };

    tariffRateAction(tariffRate);
    resetForm();
    onClose();
  };

  const validate = (values: FormValues): void | object | Promise<FormikErrors<FormValues>> => {
    const errors: FormikErrors<FormValues> = {};
    let fromError: string | undefined = undefined;
    let toError: string | undefined = undefined;
    if (!values.period.from || !values.period.from.isValid()) {
      fromError = 'Date is invalid';
    }
    if (!values.period.to || !values.period.to.isValid()) {
      toError = 'Date is invalid';
    }
    if (!fromError && !toError) {
      const currentIntervalIndexes: number[] = getIntervalsFromDates(values.period.from, values.period.to);
      const availableIndexes = ALL_INTERVAL_INDEXES.filter(intervalIndex => !disabledIntervalIndexes.includes(intervalIndex));

      const hasCollision = currentIntervalIndexes.some(index => !availableIndexes.includes(index));

      if (hasCollision) {
        if (!errors.period) {
          errors.period = {
            from: '',
            to: '',
          };
        }
        fromError = 'Interval time overlaps existing tariff rate';
        toError = 'Interval time overlaps existing tariff rate';
      }
    }
    if (fromError || toError) {
      errors.period = {};
      if (fromError) {
        errors.period.from = fromError;
      }
      if (toError) {
        errors.period.to = toError;
      }
    }
    if (!values.rate) {
      errors.rate = 'Please specify a rate';
    }
    return errors;
  };

  const { errors, handleSubmit, setFieldValue, touched, values } = useFormik({
    initialValues,
    onSubmit,
    validate,
    validateOnChange: true,
    enableReinitialize: true, // Reset the values everytime the modal is closed
  });

  const readablePeriodType = periodTypeToString(periodType);

  const onRateChange = (value: string) => {
    setFieldValue('rate', value);
  };

  const onRateTypeChange = (value: string) => {
    const rateType = value as TariffRateType;
    setFieldValue('rateType', rateType);
  };

  const onFromTimeChange = (fromTime: unknown) => {
    const dayjsTime = fromTime as Dayjs;
    if (dayjsTime.isAfter(values.period.to)) {
      setFieldValue('period', {
        from: dayjsTime,
        to: dayjsTime.add(1, 'hour'),
      });
    } else {
      setFieldValue('period', {
        ...values.period,
        from: dayjsTime,
      });
    }
  };

  const onToTimeChange = (toTime: unknown) => {
    setFieldValue('period', {
      ...values.period,
      to: toTime as Dayjs,
    });
  };

  const hasFromTimeError: boolean = !!touched.period?.from && !!errors.period?.from;
  const hasToTimeError: boolean = !!touched.period?.to && !!errors.period?.to;

  return (
    <Modal open={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit} id="time-of-use-tariff-rate">
        <ModalContainer>
          <StyledModalContent>
            <Title>{`${getTitle(title)}`}</Title>
            <Subtitle>{`What is the ${readablePeriodType} duration?`}</Subtitle>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <FlexContainer>
                <StyledTimePicker
                  label="Begin"
                  slotProps={{
                    textField: {
                      style: { width: '14rem' },
                      helperText: hasFromTimeError ? errors.period?.from + '' : '',
                      error: hasFromTimeError,
                    },
                  }}
                  value={values.period.from}
                  onChange={onFromTimeChange}
                  timeSteps={{ minutes: 30 }}
                />
                <StyledTimePicker
                  label="End"
                  slotProps={{
                    textField: {
                      style: { width: '14rem' },
                      helperText: hasToTimeError ? errors.period?.to + '' : '',
                      error: hasToTimeError,
                    },
                  }}
                  value={values.period.to}
                  onChange={onToTimeChange}
                  timeSteps={{ minutes: 30 }}
                />
              </FlexContainer>
            </LocalizationProvider>

            <Subtitle>{`What is the ${readablePeriodType} rate?`}</Subtitle>
            <div style={{ marginTop: '1rem' }}>
              <TextFieldWithTypeOptions
                id="tariff-rate"
                adornmentOptions={TARIFF_RATE_TYPE_OPTIONS}
                type="number"
                adornmentValue={values.rateType}
                onAdornmentChange={onRateTypeChange}
                onValueChange={onRateChange}
                error={touched.rate && !!errors.rate}
                helperText={errors.rate}
                value={values.rate}
                label="Rate"
              />
            </div>
          </StyledModalContent>
          <StyledButton variant="contained" size="large" type="submit">
            Done
          </StyledButton>
        </ModalContainer>
      </form>
    </Modal>
  );
};

export default TariffRateModal;
