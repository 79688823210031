import InputContainer from 'components/form/fields/InputContainer';
import TariffRateModal from 'components/time/TimeOfUseTariffModal';
import TimeOfUseTariffRates from 'components/time/TimeOfUseTariffRates';
import ToggleSelectionButtonGroup from 'components/ToggleSelectionButtonGroup';
import { useState } from 'react';
import { ALL_DAY_INDEXES, TIME_OF_USE_TARIFF_TYPE_OPTIONS, WEEKDAY_DAY_INDEXES, WEEKEND_DAY_INDEXES } from 'utils/constants';
import { getMissedIntervals } from 'utils/timeHelper';
import { tariffFormRateToTariffRate } from 'utils/transform';
import { PeriodType, TariffFormRate, TimeOfUseType } from 'utils/types';

type TimeBasedTariffProps = {
  error?: string;
  value: TariffFormRate[];
  onChange: (updatedRates: TariffFormRate[]) => void;
  title: string;
};

const setTariffDayIndexes = (dayIndexes: number[]) => (tariff: TariffFormRate) => ({
  ...tariff,
  period: { ...tariff.period, daysOfTheWeekIndex: dayIndexes },
});

const toWeekdayTariff = setTariffDayIndexes(WEEKDAY_DAY_INDEXES);
const toEverydayTariff = setTariffDayIndexes(ALL_DAY_INDEXES);
const toWeekendTariff = setTariffDayIndexes(WEEKEND_DAY_INDEXES);

const getDayIndexes = (hasWeekday = true, hasWeekend = true): number[] => [
  ...(hasWeekday ? WEEKDAY_DAY_INDEXES : []),
  ...(hasWeekend ? WEEKEND_DAY_INDEXES : []),
];

const TimeBasedTariff: React.FC<TimeBasedTariffProps> = ({ error, value, onChange, title }) => {
  const weekdayTariffRates: TariffFormRate[] = value.filter(t => t.period.daysOfTheWeekIndex.join() === WEEKDAY_DAY_INDEXES.join());
  const weekendTariffRates: TariffFormRate[] = value.filter(t => t.period.daysOfTheWeekIndex.join() === WEEKEND_DAY_INDEXES.join());
  const [timeOfUseOption, setTimeOfUseOption] = useState<TimeOfUseType>(
    !weekendTariffRates.length ? 'everyday' : 'weekdays-weekends',
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isUpdateOpen, setIsUpdateOpen] = useState<boolean>(false);
  const [isWeekday, setIsWeekday] = useState<boolean>(false);
  const [modalPeriodType, setModalPeriodType] = useState<PeriodType>('peak');
  const [modalInitial, setModalInitial] = useState<TariffFormRate | null>(null);
  const isEveryday = timeOfUseOption === 'everyday';
  const selectedDayIndexes: number[] = getDayIndexes(isWeekday, isEveryday || !isWeekday);
  const onAddWeekdayRate = (periodType: PeriodType) => {
    setModalPeriodType(periodType);
    setIsWeekday(true);
    setIsOpen(true);
  };
  const onUpdateWeekdayRate = (updatedRate: TariffFormRate) => {
    setModalInitial(updatedRate);
    setModalPeriodType(updatedRate.period.type);
    setIsUpdateOpen(true);
    setIsWeekday(true);
  };
  const onUpdateWeekendRate = (updatedRate: TariffFormRate) => {
    setModalInitial(updatedRate);
    setModalPeriodType(updatedRate.period.type);
    setIsOpen(true);
    setIsWeekday(false);
  };
  const onAddWeekendRate = (periodType: PeriodType) => {
    setModalPeriodType(periodType);
    setIsWeekday(false);
    setIsOpen(true);
  };
  const onClose = () => setIsOpen(false);
  const onUpdateClose = () => setIsUpdateOpen(false);
  const onTimeOfUseTypeChange = (option: TimeOfUseType) => {
    setTimeOfUseOption(option);
    option === 'everyday' ? onChange(weekdayTariffRates.map(toEverydayTariff)) : onChange(value.map(toWeekdayTariff));
  };

  const onAddTariffRate = (newTariffRate: TariffFormRate) => onChange([...value, newTariffRate]);
  const onUpdateTariffRate = (updatedRate: TariffFormRate) => {
    return onChange(
      value.map(t => {
        return t.id === updatedRate.id ? { ...updatedRate } : t;
      }),
    );
  };

  const onDeleteTariffRate = (tariffRateToDelete: TariffFormRate) => onChange(value.filter(t => t !== tariffRateToDelete));
  const takenIntervalIndexes: number[] = value
    .filter(t => t.period.daysOfTheWeekIndex.join() === selectedDayIndexes.join())
    .map(t => t.period.intervalsIndex)
    .flat();
  const onCopyFromWeekdayRates = () => onChange([...weekdayTariffRates, ...weekdayTariffRates.map(toWeekendTariff)]);

  const weekdayData = isEveryday ? value : weekdayTariffRates;
  const showWeekdayError = !!error && !!getMissedIntervals(weekdayData.map(tariffFormRateToTariffRate).map(r => r.period)).length;
  const showWeekendError =
    !!error && !!getMissedIntervals(weekendTariffRates.map(tariffFormRateToTariffRate).map(r => r.period)).length;

  return (
    <InputContainer title={`${title} tariff`}>
      <ToggleSelectionButtonGroup
        onChange={onTimeOfUseTypeChange}
        options={TIME_OF_USE_TARIFF_TYPE_OPTIONS}
        value={timeOfUseOption}
        sx={{ marginTop: '1rem' }}
      />
      <TimeOfUseTariffRates
        data={weekdayData}
        onAdd={onAddWeekdayRate}
        onUpdate={onUpdateWeekdayRate}
        onDelete={onDeleteTariffRate}
        title={isEveryday ? 'EVERYDAY' : 'MON-FRI'}
        hasError={showWeekdayError}
      />
      {!isEveryday && (
        <TimeOfUseTariffRates
          copyText="COPY FROM MON-FRI"
          data={weekendTariffRates}
          onAdd={onAddWeekendRate}
          onCopy={weekdayTariffRates.length ? onCopyFromWeekdayRates : undefined}
          onUpdate={onUpdateWeekendRate}
          onDelete={onDeleteTariffRate}
          title="WEEKEND"
          hasError={showWeekendError}
        />
      )}
      <TariffRateModal
        {...{ isOpen, onClose, title }}
        tariffRateAction={onAddTariffRate}
        periodType={modalPeriodType}
        dayIndexes={selectedDayIndexes}
        disabledIntervalIndexes={takenIntervalIndexes}
        initial={null}
      />
      <TariffRateModal
        {...{ onUpdateTariffRate, title }}
        isOpen={isUpdateOpen}
        onClose={onUpdateClose}
        tariffRateAction={onUpdateTariffRate}
        periodType={modalPeriodType}
        dayIndexes={selectedDayIndexes}
        disabledIntervalIndexes={takenIntervalIndexes.filter(t => !modalInitial?.period?.intervalsIndex.includes(t))}
        initial={modalInitial}
      />
    </InputContainer>
  );
};

export default TimeBasedTariff;
