import styled from '@emotion/styled';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';
import { Button, ButtonProps } from '@mui/material';
import { useStore } from 'store';

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0.5rem 0;
  align-items: center;
`;

const FlexBox = styled.div`
  flex: 1;
`;

type StepNavigationProps = {
  nextButton?: ButtonProps;
  previousButton?: ButtonProps;
  skipButton?: ButtonProps;
};

const StepNavigation: React.FC<StepNavigationProps> = ({ nextButton, previousButton, skipButton }) => {
  const { previousStep, nextStep } = useStore();
  return (
    <ButtonWrapper>
      {!previousButton?.hidden && (
        <Button
          onClick={previousStep}
          startIcon={<ArrowBack />}
          size="large"
          variant="outlined"
          style={{ textTransform: 'none' }}
          {...previousButton}
        >
          Previous
        </Button>
      )}
      <FlexBox />
      {!!skipButton && (
        <Button
          onClick={nextStep}
          size="large"
          variant="text"
          style={{ textTransform: 'none' }}
          sx={{ margin: '0 1rem' }}
          {...skipButton}
        >
          Skip
        </Button>
      )}
      {!nextButton?.hidden && (
        <Button
          onClick={nextStep}
          endIcon={<ArrowForward />}
          size="large"
          variant="contained"
          style={{ textTransform: 'none' }}
          disabled={nextButton?.disabled}
          {...nextButton}
        >
          Next
        </Button>
      )}
    </ButtonWrapper>
  );
};

export default StepNavigation;
