import { ThemeProvider } from '@emotion/react';
import styled from '@emotion/styled';
import { AppBar, ThemeProvider as MuiThemeProvider, Toolbar } from '@mui/material';
import powerSensorTextImage from 'assets/png/powersensorText.png';
import { ReactComponent as ComparisonIcon } from 'assets/svg/comparisonIcon.svg';
import { ReactComponent as MeterDataIcon } from 'assets/svg/meterDataIcon.svg';
import { ReactComponent as SummaryIcon } from 'assets/svg/summaryIcon.svg';
import { ReactComponent as TariffIcon } from 'assets/svg/tariffIcon.svg';
import Banner from 'components/Banner';
import Footer from 'components/Footer';
import StepHeader from 'components/Header';
import Instruction from 'components/Instruction';
import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import AddMeterData from 'steps/AddMeterData';
import ComparisonTariffs from 'steps/ComparisonTariffs';
import CustomerTariffForm from 'steps/CustomerTariffForm';
import CustomerTariffSummary from 'steps/CustomerTariffSummary';
import { useStore } from 'store';
import { theme } from 'theme';
import { IntervalData, Tariff } from 'utils/types';

ReactGA.initialize('G-HSMKJ9B003', {
  gaOptions: {
    debug_mode: true,
    cookieDomain: 'none',
  },
  gtagOptions: {
    debug_mode: true,
  },
});

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  max-width: 56rem;
  padding: 0 3rem;
  margin: 1.5rem auto;
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
`;

type StepObject = {
  icon: JSX.Element;
  label: string;
  content: () => JSX.Element;
  disabled?: boolean;
};

type StepByPosition = {
  [key: number]: StepObject;
};

const StaticToolbar = () => (
  <AppBar position="static">
    <Toolbar variant="dense">
      <img src={powerSensorTextImage} alt="PowerSensor" />
    </Toolbar>
  </AppBar>
);

const useStepsState = (consumptionData: IntervalData[], customerTariff?: Tariff) => {
  const [stepsState, setStepsState] = useState<StepByPosition>({
    0: {
      icon: <MeterDataIcon />,
      label: 'Your Meter Data',
      content: AddMeterData,
    },
    1: {
      icon: <TariffIcon />,
      label: 'Your Tariffs',
      content: CustomerTariffForm,
    },
    2: {
      icon: <SummaryIcon />,
      label: 'Summary',
      content: CustomerTariffSummary,
    },
    3: {
      icon: <ComparisonIcon />,
      label: 'Comparison Tariff',
      content: ComparisonTariffs,
    },
  });

  useEffect(() => {
    setStepsState(prev => ({
      ...prev,
      1: { ...prev[1], disabled: !consumptionData.length },
      2: { ...prev[2], disabled: !customerTariff },
      3: { ...prev[3], disabled: !customerTariff },
    }));
  }, [consumptionData, customerTariff]);

  return stepsState;
};

function App() {
  const { step, setStep, consumptionData, customerTariffs } = useStore();
  const [activeStep, setActiveStep] = useState(step);
  const stepsState = useStepsState(consumptionData, customerTariffs[0]);

  useEffect(() => {
    setActiveStep(step);
  }, [step]);

  const stepsWithHandlers = Object.values(stepsState).map((step, index) => ({
    ...step,
    onClick: () => {
      if (!step.disabled) {
        setStep(index);
      }
    },
  }));

  const StepContent = stepsState[activeStep].content;

  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <PageWrapper>
          <StaticToolbar />
          {step === 0 && <Banner />}
          {step === 0 && <Instruction />}
          <StepHeader steps={stepsWithHandlers} activeStep={activeStep} />
          <ContentWrapper>
            <StepContent />
          </ContentWrapper>
          <Footer />
        </PageWrapper>
      </ThemeProvider>
    </MuiThemeProvider>
  );
}

export default App;
