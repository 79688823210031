import InputContainer from 'components/form/fields/InputContainer';
import TextFieldWithTypeOptions from 'components/TextFieldWithTypeOptions';
import { TARIFF_RATE_TYPE_OPTIONS } from 'utils/constants';

type GenerationSingleTariffRateProps = React.ComponentProps<typeof TextFieldWithTypeOptions>;

const GenerationSingleTariffRate: React.FC<GenerationSingleTariffRateProps> = props => (
  <InputContainer title="Feed-in Tariff Rate">
    <TextFieldWithTypeOptions id="generation-tariff-rate" adornmentOptions={TARIFF_RATE_TYPE_OPTIONS} type="number" {...props} />
  </InputContainer>
);

export default GenerationSingleTariffRate;
