import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Typography } from '@mui/material';
import { intervalArrayToHourString } from 'utils/timeHelper';
import { TariffFormRate } from 'utils/types';

const TariffRateWrapper = styled.div`
  display: flex;
  margin: 0.5rem 0;
  color: #49454f;
`;

const StyledTariffRate = styled.div`
  border: 1px solid #49454f;
  padding-left: 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  align-items: centre;
  display: flex;
  gap: 0.5rem;
`;

const StyledIconButton = styled(IconButton)`
  color: #49454f;
  margin-left: -0.5rem;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
`;

type PeriodTariffRateProps = {
  data: TariffFormRate;
  onDelete: (tariffRate: TariffFormRate) => void;
  onUpdate: (tariffRate: TariffFormRate) => void;
};

export const PeriodTariffRate: React.FC<PeriodTariffRateProps> = ({ data: tariffRate, onDelete, onUpdate }) => {
  const allIndexes: number[] = tariffRate.period.intervalsIndex;
  const defaultValue = `${intervalArrayToHourString(allIndexes)}`;
  const handleOnDelete = () => onDelete(tariffRate);
  const handleOnUpdate = () => onUpdate(tariffRate);

  return (
    <TariffRateWrapper>
      <StyledTariffRate>
        <FlexContainer onClick={handleOnUpdate}>
          <Typography variant="body1" style={{ paddingTop: '0.5rem' }}>
            {defaultValue}
          </Typography>
          <Typography variant="body2" style={{ paddingTop: '0.7rem' }}>
            {tariffRate.rate}
            {tariffRate.type}
          </Typography>
        </FlexContainer>
        <StyledIconButton aria-label="delete" onClick={handleOnDelete}>
          <CloseIcon />
        </StyledIconButton>
      </StyledTariffRate>
    </TariffRateWrapper>
  );
};
