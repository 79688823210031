import StepNavigation from 'components/StepNavigation';
import DashedDivider from 'components/common/DashedDivider';
import { FileUploadDropzone } from 'components/meterData/FileUploadDropzone';
import MeterDataDescription from 'components/meterData/MeterDataDescription';
import FetchFromPowersensor from 'components/meterData/powersensor/FetchFromPowersensor';
import { useStore } from 'store';
import { combineConsumptionData } from 'utils/transform';

const AddMeterData = () => {
  const { consumptionData, controlledLoadData, generationData, totalConsumptionData, totalSolarData, intervalPeaks } = useStore();

  return (
    <>
      <FileUploadDropzone />
      <FetchFromPowersensor />
      <DashedDivider />
      {!!consumptionData.length && (
        <MeterDataDescription
          data={combineConsumptionData(consumptionData, controlledLoadData)}
          totalData={totalConsumptionData}
          title="Energy Used"
          dataLabel="Grid Consumption"
          solarDataLabel="Solar Consumption"
          totalByDateLabel="Total Energy Consumed by Date"
          averageByTimeLabel="Average Energy Consumed by Time of the Day"
          intervalPeaks={intervalPeaks}
        />
      )}
      {!!consumptionData.length && <DashedDivider />}
      {!!generationData.length && (
        <MeterDataDescription
          data={generationData}
          totalData={totalSolarData}
          title="Energy Exported"
          dataLabel="Exported to Grid"
          solarDataLabel="Used Generation"
          totalByDateLabel="Total Energy Exported by Date"
          averageByTimeLabel="Average Energy Exported by Time of the Day"
        />
      )}
      {!!generationData.length && <DashedDivider />}
      <StepNavigation previousButton={{ hidden: true }} nextButton={{ disabled: !consumptionData.length }} />
    </>
  );
};

export default AddMeterData;
