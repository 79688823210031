import styled from '@emotion/styled';
import { Box } from '@mui/material';

const ModalContainer = styled(Box)`
  background-color: #ffffff;
  border-radius: 28px;
  box-shadow:
    0px 6px 10px 4px rgba(0, 0, 0, 0.15),
    0px 2px 3px 0px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  left: 50%;
  min-height: 14rem;
  max-height: calc(100vh - 10rem);
  overflow-y: auto;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 6rem);
  max-width: 29rem;
  padding: 1.5rem;
`;

export default ModalContainer;
