// NOTE(pdiego): https://consumerdatastandardsaustralia.github.io/standards/#energy-apis
// Current Version: 3

export enum AERFuelType {
  ELECTRICITY = 'ELECTRICITY',
  GAS = 'GAS',
  DUAL = 'DUAL',
}

export enum AEREffective {
  CURRENT = 'CURRENT',
  FUTURE = 'FUTURE',
  ALL = 'ALL',
}

export enum AERCustomerType {
  RESIDENTIAL = 'RESIDENTIAL',
  BUSINESS = 'BUSINESS',
}

export enum AERPayerType {
  GOVERNMENT = 'GOVERNMENT',
  RETAILER = 'RETAILER',
}

export enum AERPlanType {
  STANDING = 'STANDING',
  REGULATED = 'REGULATED',
  MARKET = 'MARKET',
}

enum AERPlanTariffType {
  ENVIRONMENTAL = 'ENVIRONMENTAL',
  REGULATED = 'REGULATED',
  NETWORK = 'NETWORK',
  METERING = 'METERING',
  RETAIL_SERVICE = 'RETAIL_SERVICE',
  RCTI = 'RCTI',
  OTHER = 'OTHER',
}

export enum AERPlanTariffBlockType {
  SINGLE_RATE = 'singleRate',
  TIME_OF_USE = 'timeOfUseRates',
  DEMAND = 'demandCharges',
}

export enum AERPlanControlledTariffBlockType {
  SINGLE_RATE = 'singleRate',
  TIME_OF_USE = 'timeOfUseRates',
}

export enum AERTariffTimeOfUseType {
  PEAK = 'PEAK',
  OFF_PEAK = 'OFF_PEAK',
  SHOULDER = 'SHOULDER',
  SHOULDER1 = 'SHOULDER1',
  SHOULDER2 = 'SHOULDER2',
}

enum AERMeasureUnit {
  KWH = 'KWH',
  KVA = 'KVA',
  KVAR = 'KVAR',
  KVARH = 'KVARH',
  KW = 'KW',
  DAYS = 'DAYS',
  METER = 'METER',
  MONTH = 'MONTH',
}

enum AERMeasurementPeriod {
  DAY = 'DAY',
  MONTH = 'MONTH',
  TARIFF_PERIOD = 'TARIFF_PERIOD',
}

export enum AERDay {
  MONDAY = 'MON',
  MONDAY_FULL = 'MONDAY',
  TUESDAY = 'TUE',
  TUESDAY_FULL = 'TUESDAY',
  WEDNESDAY = 'WED',
  WEDNESDAY_FULL = 'WEDNESDAY',
  THURSDAY = 'THU',
  THURSDAY_FULL = 'THURSDAY',
  FRIDAY = 'FRI',
  FRIDAY_FULL = 'FRIDAY',
  SATURDAY = 'SAT',
  SATURDAY_FULL = 'SATURDAY',
  SUNDAY = 'SUN',
  SUNDAY_FULL = 'SUNDAY',
  PUBLIC_HOLIDAYS = 'PUBLIC_HOLIDAYS',
  BUSINESS_DAYS = 'BUSINESS_DAYS',
}

enum AERPlanPricingModel {
  SINGLE_RATE = 'SINGLE_RATE',
  SINGLE_RATE_CONT_LOAD = 'SINGLE_RATE_CONT_LOAD',
  TIME_OF_USE = 'TIME_OF_USE',
  TIME_OF_USE_CONT_LOAD = 'TIME_OF_USE_CONT_LOAD',
  FLEXIBLE = 'FLEXIBLE',
  FLEXIBLE_CONT_LOAD = 'FLEXIBLE_CONT_LOAD',
  QUOTA = 'QUOTA',
}

enum AERTimezone {
  LOCAL = 'LOCAL',
  AEST = 'AEST',
}

enum AERPaymentOption {
  PAPER_BILL = 'PAPER_BILL',
  DIRECT_DEBIT = 'DIRECT_DEBIT',
  CREDIT_CARD = 'CREDIT_CARD',
  BPAY = 'BPAY',
  OTHER = 'OTHER',
}

export enum AERScheme {
  PREMIUM = 'PREMIUM',
  OTHER = 'OTHER',
}

export enum AERSolarTariffType {
  SINGLE_TARIFF = 'singleTariff',
  TIME_VARYING_TARIFF = 'timeVaryingTariffs',
}

export enum AERPlanDiscountType {
  GUARANTEED = 'GUARANTEED',
  CONDITIONAL = 'CONDITIONAL',
  OTHER = 'OTHER',
}

enum AERPlanDiscountConditionalCategory {
  PAY_ON_TIME = 'PAY_ON_TIME',
  DIRECT_DEBIT = 'DIRECT_DEBIT',
  GUARANTEED_DISCOUNT = 'GUARANTEED_DISCOUNT',
  OTHER = 'OTHER',
}

export enum AERPlanDiscountMethod {
  PERCENT_OF_BILL = 'percentOfBill',
  PERCENT_OF_USE = 'percentOfUse',
  FIXED_AMOUNT = 'fixedAmount',
  PERCENT_OVER_THRESHOLD = 'percentOverThreshold',
}

type GenericPlanRequest = {
  postcode?: string;
  fuelType?: AERFuelType;
  effective?: AEREffective;
  type?: AERPlanType;
  page?: number;
  pageSize?: number;
};

export type PlanRequestBySingleRetailer = {
  retailer: string;
} & GenericPlanRequest;

export type PlanRequestByRetailers = {
  retailers: string[];
} & GenericPlanRequest;

export type PlanDetailRequest = {
  retailer: string;
  planId: string;
};

type AERMeteringCharge = {
  displayName: string;
  description?: string;
  minimumValue: number;
  maximumValue?: number;
  period?: string;
};

type AERGeography = {
  excludedPostcodes?: string[];
  includedPostcodes?: string[];
  distributors: string[];
};

type AERPlanAdditionaInfo = {
  overviewUri?: string;
  termsUri?: string;
  eligibilityUri?: string;
  pricingUri?: string;
  bundleUri?: string;
};

export type AERLinksPaginated = {
  first?: string;
  last?: string;
  next?: string;
  prev?: string;
  self: string;
};

export type AERMetaPaginated = {
  totalRecords: number;
  totalPages: number;
};

export type AERPlan = {
  additionalInfo?: AERPlanAdditionaInfo;
  applicationUri?: string;
  brand: string;
  brandName: string;
  customerType?: AERCustomerType;
  description?: string;
  displayName?: string;
  effectiveFrom?: string;
  effectiveTo?: string;
  fuelType: AERFuelType;
  geography?: AERGeography;
  lastUpdated: string;
  planId: string;
  type: AERPlanType;
};

export type AERPlanListResponse = {
  data: {
    plans: AERPlan[];
  };
  links: AERLinksPaginated;
  meta: AERMetaPaginated;
};

export type AERTariffRate = {
  unitPrice: string;
  measureUnit?: AERMeasureUnit;
  volume?: number;
};

type AERTariffGenericRate = {
  displayName: string;
  description?: string;
  rates: AERTariffRate[];
};

type AERTariffSingleRate = AERTariffGenericRate & {
  generalUnitPrice?: string;
  period?: string;
};

type AERTimeOfUse = {
  days: AERDay[];
  startTime: string;
  endTime: string;
};

type AERTimeVariation = {
  days: AERDay[];
  startTime?: string;
  endTime?: string;
};

export type AERTariffTimeOfUseRate = AERTariffGenericRate & {
  timeOfUse: AERTimeOfUse[];
  type: AERTariffTimeOfUseType;
};

export type AERTariffDemandCharge = {
  displayName: string;
  description?: string;
  amount: string;
  measuerUnit?: AERMeasureUnit;
  startTime: string;
  endTime: string;
  days?: AERDay[];
  minDemand?: string;
  maxDemand?: string;
  measurementPeriod: AERMeasurementPeriod;
  chargePeriod: AERMeasurementPeriod;
};

export type AERPlanTariffPeriod2 = {
  type: AERPlanTariffType;
  displayName: string;
  startDate: string;
  endDate: string;
  dailySupplyCharges?: string;
  bandedDailySupplyCharges?: AERTariffRate[];
  timeZone?: AERTimezone;
  rateBlockUType: AERPlanTariffBlockType;
  singleRate?: AERTariffSingleRate;
  timeOfUseRates?: AERTariffTimeOfUseRate[];
  demandCharges?: AERTariffDemandCharge[];
};

// NOTE (pdiego): controlledLoad doesn't have the format the documentation mentions.
export type AERPlanControlledLoad = AERTariffGenericRate & {
  dailyCharge?: string;
  period: string;
};

export type AERControlledLoadSingleRate = AERTariffGenericRate & {
  dailySupplyCharge?: string;
  period?: string;
};

export type AERControlledLoadTOURate = AERControlledLoadSingleRate & {
  timeOfUse: AERTimeOfUse[];
};

export type AERPlanControlledLoadV2 = {
  displayName: string;
  rateBlockUType: AERPlanControlledTariffBlockType;
  startDate: string;
  endDate: string;
  singleRate?: AERControlledLoadSingleRate;
  timeOfUseRates?: AERControlledLoadTOURate[];
};

export type AERPlanSolarFeedInTariffV3 = {
  displayName: string;
  description?: string;
  startDate?: string;
  endDate?: string;
  scheme: AERScheme;
  payerType: AERPayerType;
  tariffUType: AERSolarTariffType;
  singleTariff?: {
    rates: AERTariffRate[];
    period?: string;
  };
  timeVaryingTariffs?: {
    type?: AERTariffTimeOfUseType;
    rates?: AERTariffRate[];
    period?: string;
    timeVariations: AERTimeVariation[];
  };
};

export type AERPlanDiscount = {
  displayName: string;
  description?: string;
  percentOfUse?: {
    rate: string;
  };
  fixedAmount?: {
    amount: string;
  };
  percentOverThreshold?: {
    rate: string;
    usageAmount: string;
  };
  percentOfBill?: {
    rate: string;
  };
  type: AERPlanDiscountType;
  methodUType: AERPlanDiscountMethod;
  category?: AERPlanDiscountConditionalCategory;
};

export enum AERPlanFeeType {
  CC_PROCESSING = 'CC_PROCESSING',
  CHEQUE_DISHONOUR = 'CHEQUE_DISHONOUR',
  CONNECTION = 'CONNECTION',
  CONTRIBUTION = 'CONTRIBUTION',
  DD_DISHONOUR = 'DD_DISHONOUR',
  DISCONNECT_MOVE_OUT = 'DISCONNECT_MOVE_OUT',
  DISCONNECT_NON_PAY = 'DISCONNECT_NON_PAY',
  DISCONNECTION = 'DISCONNECTION',
  ESTABLISHMENT = 'ESTABLISHMENT',
  EXIT = 'EXIT',
  LATE_PAYMENT = 'LATE_PAYMENT',
  MEMBERSHIP = 'MEMBERSHIP',
  OTHER = 'OTHER',
  PAPER_BILL = 'PAPER_BILL',
  PAYMENT_PROCESSING = 'PAYMENT_PROCESSING',
  RECONNECTION = 'RECONNECTION',
}

export enum AERPlanFeeTerm {
  ONE_YEAR = '1_YEAR',
  TWO_YEARS = '2_YEAR',
  THREE_YEARS = '3_YEAR',
  FOUR_YEARS = '4_YEAR',
  FIVE_YEARS = '5_YEAR',
  FIXED = 'FIXED',
  PERCENT_OF_BILL = 'PERCENT_OF_BILL',
  ANNUAL = 'ANNUAL',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  BIANNUAL = 'BIANNUAL',
  VARIABLE = 'VARIABLE',
}

export type AERPlanFee = {
  type: AERPlanFeeType;
  term: AERPlanFeeTerm;
  amount?: string;
  rate?: string;
  description?: string;
};

enum AERPlanEligibilityType {
  EXISTING_CUSTOMER = 'EXISTING_CUST',
  EXISTING_POOL = 'EXISTING_POOL',
  EXISTING_SOLAR = 'EXISTING_SOLAR',
  EXISTING_BATTERY = 'EXISTING_BATTERY',
  EXISTING_SMART_METER = 'EXISTING_SMART_METER',
  EXISTING_BASIC_METER = 'EXISTING_BASIC_METER',
  SENIOR_CARD = 'SENIOR_CARD',
  SMALL_BUSINESS = 'SMALL_BUSINESS',
  NO_SOLAR_FIT = 'NO_SOLAR_FIT',
  NEW_CUSTOMER = 'NEW_CUSTOMER',
  ONLINE_ONLY = 'ONLINE_ONLY',
  REQ_EQUIP_SUPPLIER = 'REQ_EQUIP_SUPPLIER',
  THIRD_PARTY_ONLY = 'THIRD_PARTY_ONLY',
  SPORT_CLUB_MEMBER = 'SPORT_CLUB_MEMBER',
  ORG_MEMBER = 'ORG_MEMBER',
  SPECIFIC_LOCATION = 'SPECIFIC_LOCATION',
  MINIMUM_USAGE = 'MINIMUM_USAGE',
  LOYALTY_MEMBER = 'LOYALTY_MEMBER',
  GROUP_BUY_MEMBER = 'GROUP_BUY_MEMBER',
  CONTINGENT_PLAN = 'CONTINGENT_PLAN',
  OTHER = 'OTHER',
}

type AERPlanEligibility = {
  type: AERPlanEligibilityType;
  information: string;
  description?: string;
};

enum AERPlanIncentiveCategory {
  GIFT = 'GIFT',
  ACCOUNT_CREDIT = 'ACCOUNT_CREDIT',
  OTHER = 'OTHER',
}

export type AERPlanIncentive = {
  displayName: string;
  description: string;
  category: AERPlanIncentiveCategory;
  eligibility?: string;
};

type AERPlanContractV3 = {
  additionalFeeInformation?: string;
  pricingModel: AERPlanPricingModel;
  timeZone?: AERTimezone; // NOTE (pdiego): defaults to AEST if absent
  isFixed: boolean;
  variation?: string;
  onExpiryDescription?: string;
  paymentOption: AERPaymentOption[];
  intrinsicGreenPower?: {
    greenPercentage?: string;
  };
  controlledLoad?: AERPlanControlledLoadV2[];
  discounts?: AERPlanDiscount[];
  fees?: AERPlanFee[];
  solarFeedInTariff?: AERPlanSolarFeedInTariffV3[];
  tariffPeriod: AERPlanTariffPeriod2[];
  eligibility?: AERPlanEligibility[];
  incentives?: AERPlanIncentive[];
};

enum AERContractTermType {
  A_YEAR = '1_YEAR',
  TWO_YEARS = '2_YEAR',
  THREE_YEARS = '3_YEAR',
  FOUR_YEARS = '4_YEAR',
  FIVE_YEARS = '5_YEAR',
  ONGOING = 'ONGOING',
  OTHER = 'OTHER',
}

type AERPlanContractFullV3 = AERPlanContractV3 & {
  termType?: AERContractTermType;
  benefitPeriod?: string;
  terms?: string;
  meterTypes?: string[];
  coolingOffDays?: number;
  billFrequency: string[];
};

export type AERPlanDetailV3 = AERPlan & {
  meteringCharges?: AERMeteringCharge[];
  gasContract?: AERPlanContractFullV3;
  electricityContract: AERPlanContractFullV3; // NOTE(pdiego); Mandatory for ELECTRICITY or DUAL plans
};

export type BrandUri = {
  brand: string;
  uri: string;
};
