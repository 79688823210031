import InputContainer from 'components/form/fields/InputContainer';
import TextFieldWithTypeOptions from 'components/TextFieldWithTypeOptions';

type NameInputProps = React.ComponentProps<typeof TextFieldWithTypeOptions>;

const NameInput: React.FC<NameInputProps> = props => (
  <InputContainer
    title="Name"
    description="Identify the tariff for your own reference."
    helperText="This will be used to identify the tariff in the comparison."
  >
    <TextFieldWithTypeOptions id="name" type="text" {...props} />
  </InputContainer>
);

export default NameInput;
