import styled from '@emotion/styled';
import BlockRange from 'components/block/BlockRange';
import TextFieldWithTypeOptions from 'components/TextFieldWithTypeOptions';
import { TARIFF_RATE_TYPE_OPTIONS } from 'utils/constants';
import { TariffFormRate, TariffRateType } from 'utils/types';

const RATE_ERROR = 'Please enter a valid rate.';

type BlockTariffItemProps = {
  error?: string;
  isLast?: boolean;
  onChange: (newValue: TariffFormRate) => void;
  previousThreshold?: string;
  value: TariffFormRate;
};

const StyledBlockTariffItem = styled.div`
  margin-top: 1rem;
  flex: 1;
`;

const BlockTariffItem: React.FC<BlockTariffItemProps> = ({ previousThreshold, value, onChange, isLast = false, error }) => {
  const isInvalidRate = value.rate.length === 0 || Number(value.rate) <= 0;
  const showRateError = isInvalidRate && !!error;
  const onThresholdChange = (threshold: string) => onChange({ ...value, threshold });
  const onRateChange = (rate: string) => onChange({ ...value, rate });
  const onTypeChange = (type: string) => onChange({ ...value, type: type as TariffRateType });
  return (
    <StyledBlockTariffItem>
      <BlockRange
        previousThreshold={previousThreshold}
        threshold={value.threshold}
        onChange={onThresholdChange}
        isLast={isLast}
        error={error}
      />
      <TextFieldWithTypeOptions
        id="block-tariff-rate"
        adornmentOptions={TARIFF_RATE_TYPE_OPTIONS}
        type="number"
        value={value.rate}
        adornmentValue={value.type}
        onAdornmentChange={onTypeChange}
        onValueChange={onRateChange}
        error={showRateError}
        helperText={showRateError ? RATE_ERROR : undefined}
      />
    </StyledBlockTariffItem>
  );
};

export default BlockTariffItem;
