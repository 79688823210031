import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { PeriodType } from 'utils/types';

interface RateTypeLabelProps {
  periodType: PeriodType;
}

const StyledRateTypeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const StyledRectangle = styled.div<{ backgroundColor: string }>`
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 0.5rem;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

const StyledOptionalLabel = styled(Typography)`
  color: ${({ theme }) => theme.palette.divider};
`;

const RateTypeLabel: React.FC<RateTypeLabelProps> = ({ periodType }) => {
  const theme = useTheme();
  const colorByType = (type: PeriodType) => {
    switch (type) {
      case 'peak':
        return theme.palette.primary.main;
      case 'off_peak':
        return '#C2CBE7';
      case 'shoulder':
        return theme.palette.secondary.light;
    }
  };

  const getLabel = (periodType: PeriodType) => {
    switch (periodType) {
      case 'peak':
        return 'Peak';
      case 'off_peak':
        return 'Off Peak';
      case 'shoulder':
        return 'Shoulder';
      default:
        return periodType;
    }
  };

  return (
    <StyledRateTypeContainer>
      <StyledRectangle backgroundColor={colorByType(periodType)} />
      <Typography variant="body1" fontWeight="bold">
        {getLabel(periodType)}
      </Typography>
      {periodType === 'shoulder' && <StyledOptionalLabel variant="body2">(optional)</StyledOptionalLabel>}
    </StyledRateTypeContainer>
  );
};

export default RateTypeLabel;
