// List of CDR energy retailer base URIs
// @see https://www.aer.gov.au/energy-product-reference-data

import { BrandUri } from 'services/aer/types';

// Last updated: 2024-09-09
export const AER_RETAILERS: BrandUri[] = [
  {
    brand: '1st Energy',
    uri: '1st-energy',
  },
  {
    brand: 'ActewAGL',
    uri: 'actewagl',
  },
  {
    brand: 'Active Utilities Retail',
    uri: 'active-utilites',
  },
  {
    brand: 'AGL',
    uri: 'agl',
  },
  {
    brand: 'Alinta',
    uri: 'alinta',
  },
  {
    brand: 'Altogether',
    uri: 'altogether',
  },
  {
    brand: 'Amber Electric',
    uri: 'amber',
  },
  {
    brand: 'Ampol Energy',
    uri: 'ampol',
  },
  {
    brand: 'Arc Energy Group',
    uri: 'arc-energy',
  },
  {
    brand: 'ARCLINE by RACV',
    uri: 'arcline',
  },
  {
    brand: 'Arcstream',
    uri: 'arcstream',
  },
  {
    brand: 'Aurora Energy',
    uri: 'aurora',
  },
  {
    brand: 'Besy',
    uri: 'besy',
  },
  {
    brand: 'Blue NRG',
    uri: 'blue-nrg',
  },
  {
    brand: 'Brighte Energy',
    uri: 'brighte',
  },
  {
    brand: 'Circular Energy',
    uri: 'circular',
  },
  {
    brand: 'CleanCo Queensland',
    uri: 'cleanco',
  },
  {
    brand: 'CleanPeak Energy Retail',
    uri: 'cleanpeak',
  },
  {
    brand: 'Coles Energy',
    uri: 'coles',
  },
  {
    brand: 'Cooperative Power',
    uri: 'cooperative',
  },
  {
    brand: 'CovaU',
    uri: 'covau',
  },
  {
    brand: 'CPE Mascot',
    uri: 'cpe-mascot',
  },
  {
    brand: 'Diamond Energy',
    uri: 'diamond',
  },
  {
    brand: 'Discover Energy',
    uri: 'discover',
  },
  {
    brand: 'Dodo',
    uri: 'dodo',
  },
  {
    brand: 'EA Connect',
    uri: 'ea-connect',
  },
  {
    brand: 'Electricity in a Box',
    uri: 'electricity-in-a-box',
  },
  {
    brand: 'Energy Locals',
    uri: 'energy-locals',
  },
  {
    brand: 'EnergyAustralia',
    uri: 'energyaustralia',
  },
  {
    brand: 'Engie',
    uri: 'engie',
  },
  {
    brand: 'Ergon Energy',
    uri: 'ergon',
  },
  {
    brand: 'Evergy',
    uri: 'evergy',
  },
  {
    brand: 'Flow Power',
    uri: 'flow-power',
  },
  {
    brand: 'Flow Systems',
    uri: 'flow-systems',
  },
  {
    brand: 'Future X Power',
    uri: 'future-x',
  },
  {
    brand: 'GEE Energy',
    uri: 'gee-energy',
  },
  {
    brand: 'GloBird Energy',
    uri: 'globird',
  },
  {
    brand: 'Glow Power',
    uri: 'glow-power',
  },
  {
    brand: 'Humenergy Group',
    uri: 'humenergy',
  },
  {
    brand: 'iGENO',
    uri: 'igeno',
  },
  {
    brand: 'Indigo Power',
    uri: 'indigo',
  },
  {
    brand: 'iO Energy Retail Services',
    uri: 'io-energy',
  },
  {
    brand: 'Kogan Energy',
    uri: 'kogan',
  },
  {
    brand: 'Locality Planning Energy',
    uri: 'locality-planning',
  },
  {
    brand: 'Localvolts',
    uri: 'localvolts',
  },
  {
    brand: 'Lumo Energy',
    uri: 'lumo',
  },
  {
    brand: 'Macquarie',
    uri: 'macquarie',
  },
  {
    brand: 'Metered Energy Holdings Pty Ltd',
    uri: 'metered-energy',
  },
  {
    brand: 'Microgrid Power',
    uri: 'microgrid',
  },
  {
    brand: 'Momentun Energy',
    uri: 'momentum',
  },
  {
    brand: 'MYOB powered by OVO',
    uri: 'myob',
  },
  {
    brand: 'Nectr',
    uri: 'nectr',
  },
  {
    brand: 'Next Business Energy',
    uri: 'next-business',
  },
  {
    brand: 'Origin Energy',
    uri: 'origin',
  },
  {
    brand: 'OVO Energy',
    uri: 'ovo-energy',
  },
  {
    brand: 'Pacific Blue Retail',
    uri: 'pacific-blue',
  },
  {
    brand: 'People Energy',
    uri: 'people-energy',
  },
  {
    brand: 'Powerdirect',
    uri: 'powerdirect',
  },
  {
    brand: 'PowerHub',
    uri: 'powerhub',
  },
  {
    brand: 'Powershop',
    uri: 'powershop',
  },
  {
    brand: 'Powow Power',
    uri: 'powow',
  },
  {
    brand: 'Radian Energy',
    uri: 'radian',
  },
  {
    brand: 'Real Utilities',
    uri: 'real-utilities',
  },
  {
    brand: 'ReAmped Energy',
    uri: 'reamped',
  },
  {
    brand: 'Red Energy',
    uri: 'red-energy',
  },
  {
    brand: 'Sanctuary Energy',
    uri: 'sanctuary',
  },
  {
    brand: 'Seene',
    uri: 'seene',
  },
  {
    brand: 'Shell Energy',
    uri: 'shell-energy',
  },
  {
    brand: 'Smart Energy',
    uri: 'smart-energy',
  },
  {
    brand: 'SmartestEnergy',
    uri: 'smartestenergy',
  },
  {
    brand: 'Solstice Energy',
    uri: 'solstice',
  },
  {
    brand: 'Sonnen',
    uri: 'sonnen',
  },
  {
    brand: 'Stanwell Energy',
    uri: 'stanwell',
  },
  {
    brand: 'Sumo Gas',
    uri: 'sumo-gas',
  },
  {
    brand: 'Sumo Power',
    uri: 'sumo-power',
  },
  {
    brand: 'Tango Energy',
    uri: 'tango',
  },
  {
    brand: 'Telstra Energy',
    uri: 'telstra-energy',
  },
  {
    brand: 'Tesla Energy Ventures',
    uri: 'tesla',
  },
  {
    brand: 'YES Energy',
    uri: 'yes-energy',
  },
  {
    brand: 'ZEN Energy',
    uri: 'zen-energy',
  },
];
