import dayjs from 'dayjs';
import Decimal from 'decimal.js';
import { ALL_DAY_INDEXES, ALL_INTERVAL_INDEXES, EMPTY_INTERVALS, YEAR_DURATION } from 'utils/constants';
import {
  ControlledLoadFormRate,
  ControlledLoadRate,
  FeedData,
  IntervalData,
  PricingBreakdown,
  Tariff,
  TariffCycle,
  TariffFormRate,
  TariffFormValues,
  TariffRate,
  TariffSupply,
} from 'utils/types';
import { v4 as uuidv4 } from 'uuid';

export const decimalToNumber = (d: Decimal, decimalPlaces = 2): number => d.toDecimalPlaces(decimalPlaces).toNumber();

const getSupplyRatePerDay = (supply: TariffSupply): number => {
  switch (supply.type) {
    case '¢/day':
      return new Decimal(supply.rate).dividedBy(100).toNumber();
    case '$/day':
      return supply.rate;
    case '$/month':
      return new Decimal(supply.rate).times(12).dividedBy(365).toNumber();
    case '$/quarter':
      return new Decimal(supply.rate).times(4).dividedBy(365).toNumber();
  }
};

const standariseRate = ({ rate, type }: Pick<TariffRate, 'rate' | 'type'>): Pick<TariffRate, 'rate' | 'type'> => ({
  rate: type === '$/kWh' ? rate : new Decimal(rate).dividedBy(100).toNumber(),
  type: '$/kWh',
});

const standariseTariffRate = ({ rate, type, ...rest }: TariffRate): TariffRate => ({
  ...rest,
  ...standariseRate({ rate, type }),
});

/**
 * @param tariff with input from customer
 * @returns standarised tariff with rates expressed in $/kWh and $/day
 */
export const standariseTariff = ({
  supply,
  consumptionRates,
  controlledLoadRates,
  generationRates,
  demandRates,
  ...rest
}: Tariff): Tariff => ({
  supply: { rate: getSupplyRatePerDay(supply), type: '$/day' },
  consumptionRates: consumptionRates.map(standariseTariffRate),
  controlledLoadRates: controlledLoadRates.map(({ rate, supply }) => ({
    supply: { rate: getSupplyRatePerDay(supply), type: '$/day' },
    rate: standariseTariffRate(rate),
  })),
  generationRates: generationRates.map(standariseTariffRate),
  demandRates: demandRates.map(standariseTariffRate),
  ...rest,
});

export const tariffFormRateToTariffRate = (tariffFormRate: TariffFormRate): TariffRate => ({
  rate: Number(tariffFormRate.rate),
  type: tariffFormRate.type,
  period: tariffFormRate.period,
  threshold: tariffFormRate.threshold.length ? Number(tariffFormRate.threshold) : undefined,
});

export const controlledLoadFormRateToControlledLoadRate = (clFormRate: ControlledLoadFormRate): ControlledLoadRate => ({
  supply: {
    rate: Number(clFormRate.supplyChargeRate),
    type: clFormRate.supplyChargeType,
  },
  rate: {
    rate: Number(clFormRate.rate),
    type: clFormRate.type,
    period: {
      type: 'off_peak',
      daysOfTheWeekIndex: ALL_DAY_INDEXES,
      intervalsIndex: ALL_INTERVAL_INDEXES,
    },
  },
});

const getTariffType = (data: TariffRate[]) => {
  if (data.length > 1) {
    return data[0].threshold ? 'block' : 'time';
  }
  return 'single';
};

const tariffRateToFormValues = (tariffRate: TariffRate): TariffFormRate => ({
  id: uuidv4(),
  rate: tariffRate.rate + '',
  type: tariffRate.type,
  period: tariffRate.period,
  threshold: tariffRate.threshold ? tariffRate.threshold + '' : '',
});

const controlledLoadRateToFormValues = (controlledLoadRate: ControlledLoadRate): ControlledLoadFormRate => ({
  rate: controlledLoadRate.rate.rate + '',
  type: controlledLoadRate.rate.type,
  supplyChargeRate: controlledLoadRate.supply.rate + '',
  supplyChargeType: controlledLoadRate.supply.type,
});

export const tariffToFormValues = (
  tariff?: Tariff,
  hasSolar = false,
  startDate = dayjs(),
  isCompare = false,
  numberOfSecondaryTariffs = 0,
): TariffFormValues => {
  const { consumptionRates = [], generationRates = [], controlledLoadRates = [], name, demandRates = [] } = tariff || {};
  const clRates: ControlledLoadFormRate[] = controlledLoadRates.length
    ? controlledLoadRates.map(controlledLoadRateToFormValues)
    : Array(numberOfSecondaryTariffs).fill({
        rate: '',
        type: '¢/kWh',
        supplyChargeRate: '',
        supplyChargeType: '¢/day',
      });

  return {
    id: tariff?.id,
    consumptionRates: consumptionRates.map(tariffRateToFormValues),
    consumptionRateType: consumptionRates[0]?.type || '¢/kWh',
    consumptionTariffType: demandRates.length ? 'demand' : getTariffType(consumptionRates),
    consumptionTariffCycle: tariff?.cycle.consumption || 'yearly',
    controlledLoadRates: clRates,
    demandRates: demandRates.map(tariffRateToFormValues),
    demandRateType: demandRates[0]?.type || '¢/kWh',
    generationRates: generationRates.map(tariffRateToFormValues),
    generationRateType: generationRates[0]?.type || '¢/kWh',
    generationTariffType: getTariffType(generationRates),
    generationTariffCycle: tariff?.cycle.generation || 'yearly',
    hasSolar,
    name: !isCompare ? 'My Tariff' : (name ?? ''),
    startDate: tariff?.startDate || startDate,
    supplyChargeRate: tariff?.supply.rate ? tariff.supply.rate + '' : '',
    supplyChargeType: tariff?.supply.type || '¢/day',
  };
};

export const formValuesToTariff = (values: TariffFormValues): Tariff => ({
  id: values.id ?? uuidv4(),
  eligibility: [],
  startDate: values.startDate || dayjs(),
  consumptionRates: values.consumptionRates.map(tariffFormRateToTariffRate),
  generationRates: values.generationRates.map(tariffFormRateToTariffRate),
  controlledLoadRates: values.controlledLoadRates.map(controlledLoadFormRateToControlledLoadRate),
  name: values.name,
  supply: {
    rate: Number(values.supplyChargeRate),
    type: values.supplyChargeType,
  },
  demandRates: values.demandRates.map(t => ({
    startDate: '01-01',
    endDate: '12-31',
    ...tariffFormRateToTariffRate(t),
  })),
  cycle: {
    consumption: values.consumptionTariffCycle,
    generation: values.generationTariffCycle,
  },
});

export const getCost = (breakdown: PricingBreakdown): number => {
  const { generation, consumption, demand, supply } = breakdown;
  return new Decimal(consumption).plus(demand).plus(supply).minus(generation).toDecimalPlaces(2).toNumber();
};

export const cycleToMonths = (cycle: TariffCycle): number => {
  switch (cycle) {
    case 'monthly':
      return 1;
    case 'bi-monthly':
      return 2;
    case 'quarterly':
      return 3;
    case 'yearly':
      return 12;
  }
};

export const getAnnualEstimate = (price: number, totalDays: number): number =>
  new Decimal(price).times(totalDays).dividedBy(YEAR_DURATION).toNumber();

/**
 * @param breakdown cost breakdown for the interval data
 * @param totalDays days of interval data
 * @returns annual estimate of the PricingBreakdown, assuming year consumption will
 * be similar to the consumption for the given days.
 */
export const pricingBreakdownToAnnualEstimate = (breakdown: PricingBreakdown, totalDays: number): PricingBreakdown => {
  const { consumption, generation, supply, demand, controlledLoad, controlledLoadTotalSupply, controlledLoadTotalUsage } =
    breakdown;
  return {
    consumption: getAnnualEstimate(consumption, totalDays),
    generation: getAnnualEstimate(generation, totalDays),
    supply: getAnnualEstimate(supply, totalDays),
    demand: getAnnualEstimate(demand, totalDays),
    controlledLoad: controlledLoad.map(({ supply, rate }) => ({
      supply: getAnnualEstimate(supply, totalDays),
      rate: getAnnualEstimate(rate, totalDays),
    })),
    controlledLoadTotalSupply: getAnnualEstimate(controlledLoadTotalSupply, totalDays),
    controlledLoadTotalUsage: getAnnualEstimate(controlledLoadTotalUsage, totalDays),
  };
};

export const getTotal = (breakdown: PricingBreakdown) => {
  const { consumption, generation, supply, demand, controlledLoad } = breakdown;
  return decimalToNumber(
    new Decimal(consumption)
      .plus(demand)
      .plus(supply)
      .plus(controlledLoad.reduce((acc, current) => current.supply + current.rate + acc, 0))
      .minus(generation),
  );
};

export const getDayAverageCost = (breakdown: PricingBreakdown, totalDays: number) => {
  const { consumption, generation, supply, demand } = breakdown;
  return new Decimal(consumption).plus(demand).plus(supply).minus(generation).dividedBy(totalDays).toDecimalPlaces(2).toNumber();
};

export const fillMissingIntervalData = (fullData: IntervalData[], partialData: IntervalData[], suffix = ''): IntervalData[] => {
  const diffLength = fullData.length - partialData.length;
  return [
    ...fullData.slice(0, diffLength).map(data => ({
      date: data.date,
      intervals: [...EMPTY_INTERVALS],
      suffix,
    })),
    ...partialData,
  ];
};

export const combineConsumptionData = (consumptionData: FeedData, controlledLoadData: FeedData[]) => {
  return consumptionData.map((d, position) => ({
    ...d,
    intervals: d.intervals.map(
      (interval, intervalIndex) =>
        interval + controlledLoadData.reduce((acc, current) => acc + current[position].intervals[intervalIndex], 0),
    ),
  }));
};
