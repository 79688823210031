import { createTheme, darken, lighten, responsiveFontSizes, Theme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    tertiary: Palette['primary'];
  }
  interface PaletteOptions {
    tertiary?: PaletteOptions['primary'];
  }
}

const greyColor = '#919EAB';

const greyShades = {
  50: lighten(greyColor, 0.9),
  100: lighten(greyColor, 0.7),
  200: lighten(greyColor, 0.5),
  300: lighten(greyColor, 0.3),
  400: lighten(greyColor, 0.1),
  500: greyColor,
  600: darken(greyColor, 0.1),
  700: darken(greyColor, 0.2),
  800: darken(greyColor, 0.3),
  900: darken(greyColor, 0.4),
  A100: lighten(greyColor, 0.7),
  A200: lighten(greyColor, 0.5),
  A400: lighten(greyColor, 0.3),
  A700: darken(greyColor, 0.2),
};

export const theme: Theme = responsiveFontSizes(
  createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#21366C',
        light: '#5A92D2',
        dark: '#20105D',
      },
      secondary: {
        main: '#67BD0A',
        dark: '#1D192B',
        light: '#C3E1AD',
      },
      tertiary: {
        main: '#7D5260',
        dark: '#30111D',
        light: '#A2D9D9',
      },
      error: {
        main: '#B3251D',
        dark: '#410F0B',
        light: '#75B8B8',
      },
      grey: greyShades,
      divider: '#79747E',
      text: {
        primary: '#212B36',
        secondary: '#637381',
        disabled: greyColor,
      },
    },
    components: {
      MuiInput: {
        styleOverrides: {
          root: {
            borderColor: '#22376D',
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            borderColor: '#22376D',
          },
        },
      },
    },
    typography: {
      h1: {
        color: '#22376D',
      },
      h2: {
        color: '#22376D',
      },
      h3: {
        color: '#22376D',
      },
      h4: {
        color: '#22376D',
      },
      h5: {
        color: '#22376D',
      },
      h6: {
        color: '#22376D',
      },
    },
  }),
);
