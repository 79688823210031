import axios, { AxiosInstance, AxiosRequestConfig, RawAxiosRequestHeaders } from 'axios';

export type AuthenticatedRequest = {
  authToken: string;
};

type ApiParams = {
  baseURL: string;
  timeout?: number;
  headers?: Partial<RawAxiosRequestHeaders>;
};

const createAxiosInstance = ({ baseURL, timeout = 30000, headers }: ApiParams): AxiosInstance => {
  const requestConfig: AxiosRequestConfig = {
    baseURL,
    timeout,
    headers,
  };

  return axios.create(requestConfig);
};
const getConfigWithToken = (config?: AxiosRequestConfig, authToken?: string): AxiosRequestConfig | undefined => {
  if (authToken) {
    const headers = {
      Authorization: `Bearer ${authToken}`,
      ...(config?.headers || {}),
    };
    return {
      ...config,
      headers,
    };
  }
  return config;
};

export const createApi = (apiParams: ApiParams) => {
  const axiosInstance: AxiosInstance = createAxiosInstance(apiParams);
  type RequestParams = {
    config?: AxiosRequestConfig;
    authToken?: string;
  };

  type GetParams = RequestParams & { params?: Record<string, unknown> };
  type PostParams = {
    body?: unknown;
  } & RequestParams;

  const get = (url: string, params?: GetParams) => {
    const { config, authToken, params: queryParams } = params || {};
    const configWithToken = getConfigWithToken(config, authToken);
    return axiosInstance.get(url, { ...configWithToken, params: queryParams });
  };

  const post = (url: string, params?: PostParams) => {
    const { body, config, authToken } = params || {};
    const configWithToken = getConfigWithToken(config, authToken);
    return axiosInstance.post(url, body, configWithToken);
  };

  return {
    get,
    post,
  };
};
