import { Button, Modal } from '@mui/material';
import ModalContainer from 'components/common/ModalContainer';
import GetPowersensorAccount from 'components/meterData/powersensor/GetPowersensorAccount';
import SelectPowersensorAccount from 'components/meterData/powersensor/SelectPowersensorAccount';
import { useEffect, useState } from 'react';
import { useStore } from 'store';

const FetchFromPowersensor = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);
  const { accountInfo, consumptionData } = useStore();
  useEffect(handleCloseModal, [consumptionData]);

  return (
    <>
      <Button
        variant="contained"
        size="large"
        fullWidth={false}
        sx={{ margin: '0 auto' }}
        onClick={handleOpenModal}
        style={{ textTransform: 'none' }}
      >
        Fetch From Powersensor
      </Button>
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <ModalContainer>
          {!accountInfo && <GetPowersensorAccount />}
          {accountInfo && <SelectPowersensorAccount />}
        </ModalContainer>
      </Modal>
    </>
  );
};

export default FetchFromPowersensor;
