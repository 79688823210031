import dayjs from 'dayjs';
import { powersensorApi } from 'services/powersensor/api';
import {
  HouseholdAccumulationRequest,
  HouseholdPeakRequest,
  HouseholdRequest,
  PowerSensorDataItem,
  PowerSensorAccountData,
} from 'services/powersensor/types';
import { cycleToMonths } from 'utils/transform';

const ENDPOINT = '/household-shared';

export const getHouseholdData = async ({
  authToken,
  startTime,
  endTime,
  emails,
  interval = '30min',
}: HouseholdRequest): Promise<PowerSensorAccountData> => {
  const body = {
    startTime: startTime.toISOString(),
    endTime: endTime.toISOString(),
    interval,
    emails,
  };
  try {
    const { data } = await powersensorApi.post(ENDPOINT, { body, authToken });
    const result = data.items[0];
    return result;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error getting powersensor data', error);
    throw error;
  }
};

export const getCurrentAccumulatedData = async ({
  authToken,
  tariff,
  emails,
}: HouseholdAccumulationRequest): Promise<{
  exported: number;
  imported: number;
}> => {
  const endTime = dayjs();
  const anniversaryDate = tariff.startDate;
  let startTime = dayjs(anniversaryDate).year(endTime.year());
  if (endTime.subtract(1, 'day').isBefore(startTime, 'day')) {
    startTime = startTime.subtract(1, 'year');
  }
  const exportedAccumulationLength = cycleToMonths(tariff.cycle.generation);
  const importedAccumulationLength = cycleToMonths(tariff.cycle.consumption);

  const body = {
    startTime: startTime.toISOString(),
    endTime: endTime.toISOString(),
    interval: '1month',
    emails,
  };

  try {
    const { data } = await powersensorApi.post(ENDPOINT, { body, authToken });
    const items: PowerSensorDataItem[] = data.items[0].data;
    const result = {
      exported: items.slice(-exportedAccumulationLength).reduce((acc, item) => acc + (item.exported ?? 0), 0),
      imported: items.slice(-importedAccumulationLength).reduce((acc, item) => acc + (item.imported ?? 0), 0),
    };
    return result;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error getting accumulated data', error);
    throw error;
  }
};

export const getCurrentPeak = async ({ authToken, emails }: HouseholdPeakRequest): Promise<number> => {
  const endTime = dayjs();
  const startTime = endTime.subtract(1, 'month');
  const body = {
    startTime: startTime.toISOString(),
    endTime: endTime.toISOString(),
    interval: '30min',
    emails,
  };

  try {
    const { data } = await powersensorApi.post(ENDPOINT, { body, authToken });
    const items: PowerSensorDataItem[] = data.items[0].data;
    const result = items.reduce((acc, item) => Math.max(acc, item.imported ?? 0), 0);
    return result;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error getting current month peak', error);
    throw error;
  }
};
