import BouncingDotsLoader from 'components/BouncingDotsLoader';
import StepNavigation from 'components/StepNavigation';
import { Suspense, lazy } from 'react';

const LazyTariffSummary = lazy(() => import('components/summary/TariffSummary'));

const CustomerTariffSummary = () => (
  <>
    <Suspense fallback={<BouncingDotsLoader />}>
      <LazyTariffSummary />
    </Suspense>
    {/* <RealTimeCost /> */}
    <StepNavigation />
  </>
);

export default CustomerTariffSummary;
