import { aerApi } from 'services/aer/api';
import {
  AEREffective,
  AERFuelType,
  AERPlan,
  AERPlanDetailV3,
  AERPlanListResponse,
  PlanDetailRequest,
  PlanRequestByRetailers,
  PlanRequestBySingleRetailer,
} from 'services/aer/types';

const ENDPOINT = '/cds-au/v1/energy/plans';

export const getGenericPlans = async ({
  retailer,
  fuelType = AERFuelType.ELECTRICITY,
  effective = AEREffective.CURRENT,
  type,
  page,
  pageSize,
}: PlanRequestBySingleRetailer): Promise<AERPlan[]> => {
  try {
    const { data } = await aerApi.get(`/${retailer}${ENDPOINT}`, {
      params: {
        fuelType,
        effective,
        type,
        page,
        pageSize,
      },
    });
    return data.data.plans;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error getting generic plans', error);
    throw error;
  }
};

export const getAllGenericPlans = async ({
  retailer,
  postcode,
  fuelType = AERFuelType.ELECTRICITY,
  effective = AEREffective.CURRENT,
  type,
}: PlanRequestBySingleRetailer): Promise<AERPlan[]> => {
  try {
    const result = await aerApi.get(`/${retailer}${ENDPOINT}`, {
      params: {
        fuelType,
        effective,
        type,
      },
    });
    const data: AERPlanListResponse = result.data;
    let plans: AERPlan[] = data.data.plans;
    if (data.links?.next) {
      await Promise.all([
        ...Array.from(Array(data.meta.totalPages - 1)).map((_, index) =>
          getGenericPlans({
            retailer,
            fuelType,
            effective,
            type,
            page: index + 2,
            pageSize: plans.length,
          }),
        ),
      ]).then(responses => {
        plans = [...plans, ...responses.flatMap(response => response)];
      });
    }
    // NOTE (pdiego): Observed that some planIds are duplicated across pages
    const uniquePlanIds = new Set<string>();
    plans = plans.filter(plan => {
      if (uniquePlanIds.has(plan.planId)) {
        return false;
      }
      uniquePlanIds.add(plan.planId);
      return true;
    });

    if (postcode) {
      return plans.filter(plan => {
        const { includedPostcodes = [], excludedPostcodes = [] } = plan.geography || {};
        return includedPostcodes.length > 0 ? includedPostcodes.includes(postcode) : !excludedPostcodes.includes(postcode);
      });
    }

    return plans;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error getting all generic plans', error);
    throw error;
  }
};

export const getPlanDetail = async ({ retailer, planId }: PlanDetailRequest): Promise<AERPlanDetailV3> => {
  try {
    const uri = `/${retailer}${ENDPOINT}/${planId}`;
    const { data } = await aerApi.get(uri);
    return data.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error getting plan detail', error);
    throw error;
  }
};

export const getAllPlanDetails = async ({
  retailer,
  postcode,
  fuelType = AERFuelType.ELECTRICITY,
  effective = AEREffective.CURRENT,
}: PlanRequestBySingleRetailer): Promise<AERPlanDetailV3[]> => {
  if (!retailer) {
    throw new Error('Retailer is required');
  }
  try {
    const plans = await getAllGenericPlans({
      retailer,
      postcode,
      fuelType,
      effective,
    });
    const planDetailsResults = await Promise.allSettled(plans.map(plan => getPlanDetail({ retailer, planId: plan.planId }))).then(
      results => results.filter(result => result.status === 'fulfilled'),
    );
    return planDetailsResults.map(result => (result as PromiseFulfilledResult<AERPlanDetailV3>).value);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error getting all plan details', error);
    throw error;
  }
};

export const getAllPlanDetailsForAllRetailers = async ({
  retailers,
  postcode,
  fuelType = AERFuelType.ELECTRICITY,
  effective = AEREffective.CURRENT,
}: PlanRequestByRetailers): Promise<AERPlanDetailV3[]> => {
  try {
    const promises = retailers.map(retailer =>
      getAllPlanDetails({
        retailer,
        postcode,
        fuelType,
        effective,
      }),
    );
    return Promise.all(promises).then(responses => {
      return responses.flatMap(response => response);
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error getting all plan details for all retailers', error);
    throw error;
  }
};
