import { AxiosError } from 'axios';
import { useState } from 'react';

type MutationResult<T> = {
  called: boolean;
  data?: T;
  error?: string;
  loading: boolean;
};

type MutationProperties<T> = {
  defaultErrorMessage?: string;
  onCalled?: () => void;
  onError?: (e: AxiosError) => void;
  onSuccess?: (data: T) => void;
};

const UNKNOWN_ERROR = 'Error, please try again';

// NOTE(pdiego): Name is misleading as can be used for queries as well
const useMutation = <T, U>(
  promise: (params: U) => Promise<T>,
  properties: MutationProperties<T> = {},
): [mutation: (params: U) => void, result: MutationResult<T>] => {
  const { defaultErrorMessage = UNKNOWN_ERROR, onCalled, onError, onSuccess } = properties;
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<T | undefined>();
  const [called, setCalled] = useState<boolean>(false);

  const initialize = (): void => {
    onCalled?.();
    setCalled(true);
    setLoading(true);
    setError(undefined);
  };

  const handleSuccess = (rs: T): void => {
    setData(rs);
    setLoading(false);
    onSuccess?.(rs);
  };

  const handleError = (e: AxiosError): void => {
    // eslint-disable-next-line no-console
    console.error(e);
    setLoading(false);
    const message = e.message || defaultErrorMessage;
    setError(message);
    onError?.(e);
  };

  const mutation = (params: U) => {
    initialize();
    promise(params).then(handleSuccess).catch(handleError);
  };

  return [mutation, { called, data, error, loading }];
};

export default useMutation;
