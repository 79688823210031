import InputContainer from 'components/form/fields/InputContainer';
import TextFieldWithTypeOptions from 'components/TextFieldWithTypeOptions';
import { TARIFF_RATE_TYPE_OPTIONS } from 'utils/constants';

type DemandTariffRateProps = React.ComponentProps<typeof TextFieldWithTypeOptions>;
const DemandTariffRate: React.FC<DemandTariffRateProps> = props => (
  <InputContainer
    description="What demand rate are you charged?"
    helperText="This may be referred as 'Peak demand charge' or 'demand charge'."
  >
    <TextFieldWithTypeOptions id="demand-tariff-rate" adornmentOptions={TARIFF_RATE_TYPE_OPTIONS} type="number" {...props} />
  </InputContainer>
);

export default DemandTariffRate;
