import styled from '@emotion/styled';
import AppleIcon from '@mui/icons-material/Apple';
import GoogleIcon from '@mui/icons-material/Google';
import { Box, Button, CircularProgress, TextField, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import FieldContainer from 'components/meterData/FieldContainer';
import { FirebaseError } from 'firebase/app';
import {
  AuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
  UserCredential,
} from 'firebase/auth';
import useMutation from 'hooks/useMutation';
import useQuery from 'hooks/useQuery';
import { useMemo, useState } from 'react';
import { getAccountInfo } from 'services/powersensor/account';
import { decodeFirebaseError, getFirebaseAuthToken } from 'services/powersensor/firebase/auth';
import { auth } from 'services/powersensor/firebase/firebase';
import { useStore } from 'store';

const OrContainer = styled(Typography)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5rem 0;
`;

const SelectionContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const GetPowersensorAccount = () => {
  const { authToken = '', setAccountInfo, setAuthToken } = useStore();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const handleAxiosError = (e: AxiosError) => setError(e.message);
  const handleFirebaseError = (e: unknown) => {
    setError(e instanceof FirebaseError ? decodeFirebaseError(e) : 'An unknown error occurred.');
  };
  const [firebaseAuthenticate, { loading: firebaseAuthenticating }] = useMutation(getFirebaseAuthToken, {
    onSuccess: setAuthToken,
    onError: handleAxiosError,
  });
  const params = useMemo(() => ({ authToken }), [authToken]);
  const { loading: loadingAccount } = useQuery(getAccountInfo, params, {
    skip: !authToken.length,
    onSuccess: setAccountInfo,
    onError: handleAxiosError,
  });

  const handleAuthentication = async (credential: UserCredential) => {
    const token = await credential.user.getIdToken();
    firebaseAuthenticate({ idToken: token });
  };

  const handleEmailLogin = async () => {
    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
      handleAuthentication(result);
    } catch (error) {
      handleFirebaseError(error);
    }
  };

  const handleSocialLogin = async (provider: AuthProvider) => {
    try {
      const result = await signInWithPopup(auth, provider);
      handleAuthentication(result);
    } catch (error) {
      handleFirebaseError(error);
    }
  };

  const handleGoogleLogin = async () => await handleSocialLogin(new GoogleAuthProvider());

  const handleAppleLogin = async () => {
    const provider = new OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');
    await handleSocialLogin(provider);
  };

  const loading = loadingAccount || firebaseAuthenticating;

  return (
    <>
      <FieldContainer title="Email">
        <TextField
          label={email === '' ? 'Email' : ''}
          type="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
          InputLabelProps={{ shrink: false }}
        />
      </FieldContainer>
      <FieldContainer title="Password">
        <TextField
          label={password === '' ? 'Password' : ''}
          type="password"
          value={password}
          onChange={e => setPassword(e.target.value)}
          InputLabelProps={{ shrink: false }}
        />
      </FieldContainer>
      <Button onClick={handleEmailLogin} disabled={loading} variant="contained">
        {loading ? <CircularProgress size={24} color="inherit" /> : 'Retrieve accounts'}
      </Button>
      <OrContainer variant="body2">-- Or --</OrContainer>
      <SelectionContainer>
        <Button startIcon={<GoogleIcon />} onClick={handleGoogleLogin} variant="outlined" disabled={loading} sx={{ flex: 1 }}>
          Sign in with Google
        </Button>
        <Button startIcon={<AppleIcon />} onClick={handleAppleLogin} variant="outlined" disabled={loading} sx={{ flex: 1 }}>
          Sign in with Apple
        </Button>
      </SelectionContainer>
      {error && (
        <Typography variant="caption" color="error">
          {error}
        </Typography>
      )}
    </>
  );
};

export default GetPowersensorAccount;
