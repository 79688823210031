import { AuthenticatedRequest } from 'services/http';
import { powersensorApi } from 'services/powersensor/api';
import { AccountInfo } from 'services/powersensor/types';

const ENDPOINT = '/account';

export const getAccountInfo = async ({ authToken }: AuthenticatedRequest): Promise<AccountInfo> => {
  try {
    const { data } = await powersensorApi.get(`${ENDPOINT}/info`, {
      authToken,
    });
    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error getting account info', error);
    throw error;
  }
};
