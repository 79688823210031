import styled from '@emotion/styled';
import InputContainer from 'components/form/fields/InputContainer';
import TextFieldWithTypeOptions from 'components/TextFieldWithTypeOptions';
import React from 'react';
import { DAILY_SUPPLY_CHARGE_TYPE_OPTIONS, TARIFF_RATE_TYPE_OPTIONS } from 'utils/constants';
import { ControlledLoadFormRate, SupplyChargeType, TariffRateType } from 'utils/types';

type SecondaryTariffsProps = {
  error?: string[];
  onValueChange: (value: ControlledLoadFormRate[]) => void;
  value: ControlledLoadFormRate[];
};

type SingleSecondaryTariffProps = {
  error?: string;
  onValueChange: (value: ControlledLoadFormRate) => void;
  value: ControlledLoadFormRate;
  position: number;
};

const SingleSecondaryTariffWrapper = styled.div`
  margin-left: 1rem;
`;

const SingleSecondaryTariff: React.FC<SingleSecondaryTariffProps> = ({ error, value, onValueChange, position }) => {
  const onSupplyChargeTypeChange = (supplyChargeType: string) =>
    onValueChange({
      ...value,
      supplyChargeType: supplyChargeType as SupplyChargeType,
    });
  const onSupplyChargeChange = (supplyChargeRate: string) =>
    onValueChange({
      ...value,
      supplyChargeRate,
    });
  const onUnitRateTypeChange = (type: string) =>
    onValueChange({
      ...value,
      type: type as TariffRateType,
    });
  const onUnitRateChange = (rate: string) =>
    onValueChange({
      ...value,
      rate,
    });
  const hasSupplyRateError = !!error && Number(value.supplyChargeRate) <= 0;
  const hasUnitRateError = !!error && Number(value.rate) <= 0;
  const positionName = position + 1;
  return (
    <SingleSecondaryTariffWrapper>
      <InputContainer
        title={`Daily Supply - Controlled Load ${positionName}`}
        description="How much is the daily charge for your secondary tariff?"
        helperText="An electricity tariff will include a fixed charge that is specified either by day, month or quarter. This is often called a 'Service to property', 'Daily supply charge'."
      >
        <TextFieldWithTypeOptions
          id={`supplyChargeRateControlledLoad${position}`}
          adornmentOptions={DAILY_SUPPLY_CHARGE_TYPE_OPTIONS}
          type="number"
          adornmentValue={value.supplyChargeType}
          onAdornmentChange={onSupplyChargeTypeChange}
          value={value.supplyChargeRate}
          onValueChange={onSupplyChargeChange}
          error={hasSupplyRateError}
          helperText={hasSupplyRateError ? 'Invalid Daily Rate' : undefined}
        />
      </InputContainer>
      <InputContainer
        title={`Usage Rate - Controlled Load ${positionName}`}
        description="What tariff rate are you charged for your secondary tariff?"
      >
        <TextFieldWithTypeOptions
          id="consumption-tariff-rate"
          adornmentOptions={TARIFF_RATE_TYPE_OPTIONS}
          type="number"
          adornmentValue={value.type}
          error={hasUnitRateError}
          helperText={hasUnitRateError ? 'Invalid Unit Rate' : undefined}
          onAdornmentChange={onUnitRateTypeChange}
          onValueChange={onUnitRateChange}
          value={value.rate}
        />
      </InputContainer>
    </SingleSecondaryTariffWrapper>
  );
};

const SecondaryTariffs: React.FC<SecondaryTariffsProps> = ({ error, value, onValueChange }) => {
  const text = value.length > 1 ? `${value.length} secondary tariffs` : 'a secondary tariff';
  return (
    <InputContainer
      title="Secondary Tariffs"
      description={`From your data we have detected that you currently got ${text} that don't need constant supply of power`}
    >
      {value.map((tariff, i) => {
        const currentError = error?.[i];
        const onChange = (cl: ControlledLoadFormRate) => {
          const newValue = [...value];
          newValue[i] = cl;
          onValueChange(newValue);
        };
        return <SingleSecondaryTariff value={tariff} error={currentError} onValueChange={onChange} key={i} position={i} />;
      })}
    </InputContainer>
  );
};

export default SecondaryTariffs;
