import {
  BarPeriodOption,
  ChannelOption,
  Period,
  PeriodType,
  SupplyChargeType,
  TariffCycle,
  TariffFormRate,
  TariffRateType,
  TimeOfUseOption,
} from 'utils/types';
import { v4 as uuidv4 } from 'uuid';

export const DAILY_SUPPLY_CHARGE_TYPE_OPTIONS: SupplyChargeType[] = ['¢/day', '$/day', '$/month'];
export const TARIFF_RATE_TYPE_OPTIONS: TariffRateType[] = ['¢/kWh', '$/kWh'];
export const CONSUMPTION_TYPE_OPTIONS: ChannelOption[] = [
  {
    value: 'single',
    label: 'Single Rate',
  },
  {
    value: 'time',
    label: 'Time of Use',
  },
  {
    value: 'block',
    label: 'Block',
  },
  {
    value: 'demand',
    label: 'Demand',
  },
];
export const GENERATION_TYPE_OPTIONS: ChannelOption[] = [
  {
    value: 'single',
    label: 'Single rate feed-in',
  },
  {
    value: 'time',
    label: 'Time of use feed-in',
  },
  {
    value: 'block',
    label: 'Block feed-in',
  },
];
export const TIME_OF_USE_TARIFF_TYPE_OPTIONS: TimeOfUseOption[] = [
  {
    value: 'everyday',
    label: 'EVERYDAY',
  },
  {
    value: 'weekdays-weekends',
    label: 'MON-FRI / WEEKEND',
  },
];
export const BAR_PERIOD_TYPE_OPTIONS: BarPeriodOption[] = [
  {
    value: 'month',
    label: 'Month',
  },
  {
    value: 'day',
    label: 'Day',
  },
];
export const INTERVAL_LENGTH = 48;
export const EMPTY_INTERVALS: number[] = Array(INTERVAL_LENGTH).fill(0);
export const ALL_INTERVAL_INDEXES = Array.from(Array(INTERVAL_LENGTH).keys());
export const WEEKDAY_DAY_INDEXES = [1, 2, 3, 4, 5];
export const WEEKEND_DAY_INDEXES = [0, 6];
export const ALL_DAY_INDEXES = [...WEEKDAY_DAY_INDEXES, ...WEEKEND_DAY_INDEXES];

export const SINGLE_PERIOD: Period = {
  type: 'peak',
  daysOfTheWeekIndex: ALL_DAY_INDEXES, // all days in a week
  intervalsIndex: ALL_INTERVAL_INDEXES, // all 30min intervals in a day
};

export const INITIAL_BLOCK_TARIFF_FORM_RATES: TariffFormRate[] = [
  {
    id: uuidv4(),
    rate: '',
    type: '¢/kWh',
    period: SINGLE_PERIOD,
    threshold: '4000',
  },
  {
    id: uuidv4(),
    rate: '',
    type: '¢/kWh',
    period: SINGLE_PERIOD,
    threshold: '',
  },
];

export const YEAR_DURATION = 365;
export const MONTHS_IN_YEAR = 12;

export const PERIOD_TYPE_OPTIONS: PeriodType[] = ['peak', 'off_peak', 'shoulder'];

export const TARIFF_CYCLE_OPTIONS: TariffCycle[] = ['yearly', 'monthly', 'bi-monthly', 'quarterly'];

export const ONE_DAY_IN_MS = 1000 * 60 * 60 * 24;
export const THIRTY_MIN_IN_MS = 1000 * 60 * 30;

// Valid record indicators in accordance with 'AEMO meter data file format
// specification NEM12' not NEM 13.
// @see https://www.aemo.com.au
export const RECORD_INDICATORS = [100, 200, 300, 400, 500, 900];

// Valid interval lengths in minutes for interval metering data
// @see https://www.aemo.com.au
export const VALID_INTERVAL_LENGTHS = [30, 15, 5];

export const GST_RATE = 0.1;
