type HslAttributes = {
  hue?: number;
  saturation?: number;
  light?: number;
};

const randomInt = (max: number, min = 0) => Math.floor(Math.random() * (max - min + 1)) + min;

const hslToHex = (hue: number, saturation: number, light: number) => {
  const lightPercentage = light / 100;
  const a = (saturation * Math.min(lightPercentage, 1 - lightPercentage)) / 100;
  const f = (n: number) => {
    const k = (n + hue / 30) % 12;
    const color = lightPercentage - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, '0');
  };
  return `#${f(0)}${f(8)}${f(4)}`;
};

const getHexColorWithHslAttributes = (fixedHsl?: HslAttributes) => {
  const hue = fixedHsl?.hue || randomInt(360); // Degrees
  const saturation = fixedHsl?.saturation || randomInt(100);
  const light = fixedHsl?.light || randomInt(100);
  return hslToHex(hue, saturation, light);
};

export const getRandomColors = (length: number): string[] => {
  const totalDegrees = 360;
  const portion = totalDegrees / (length + 1);
  return [...Array(length)].map((_, i) =>
    getHexColorWithHslAttributes({
      hue: portion * (i + 1),
      saturation: 30,
      light: 60,
    }),
  );
};
