import styled from '@emotion/styled';
import { Typography } from '@mui/material';

const FieldContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 1rem;
  max-width: 30rem;
`;

type FieldContainerProps = {
  title: string;
  children: React.ReactNode;
};

const FieldContainer: React.FC<FieldContainerProps> = ({ title, children }) => (
  <FieldContainerWrapper>
    <Typography variant="h6">{title}</Typography>
    {children}
  </FieldContainerWrapper>
);

export default FieldContainer;
