import styled from '@emotion/styled';
import { Button, CircularProgress, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import FieldContainer from 'components/meterData/FieldContainer';
import useMutation from 'hooks/useMutation';
import ReactGA from 'react-ga4';
import { getHouseholdData } from 'services/powersensor/household';
import { PowerSensorAccountData } from 'services/powersensor/types';
import { useStore } from 'store';
import { powerSensorDataToUsageData } from 'utils/dataReader';

const fetchAnnualData = async ({ authToken, email }: { email: string; authToken: string }): Promise<PowerSensorAccountData> => {
  const now = new Date();
  now.setHours(0, 0, 0, 0);
  const aYearAgo = new Date(now);
  aYearAgo.setFullYear(aYearAgo.getFullYear() - 1);
  const nineMonthsAgo = new Date(now);
  nineMonthsAgo.setMonth(nineMonthsAgo.getMonth() - 9);
  const sixMonthsAgo = new Date(now);
  sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
  const threeMonthsAgo = new Date(now);
  threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
  const emails = [email];

  const [firstQuarterResponse, secondQuarterResponse, thirdQuarterResponse, forthQuarterResponse] = await Promise.all([
    getHouseholdData({
      authToken,
      startTime: aYearAgo,
      endTime: nineMonthsAgo,
      emails,
    }),
    getHouseholdData({
      authToken,
      startTime: nineMonthsAgo,
      endTime: sixMonthsAgo,
      emails,
    }),
    getHouseholdData({
      authToken,
      startTime: sixMonthsAgo,
      endTime: threeMonthsAgo,
      emails,
    }),
    getHouseholdData({
      authToken,
      startTime: threeMonthsAgo,
      endTime: now,
      emails,
    }),
  ]);

  const { data: firstQuarterData, ...accountInfo } = firstQuarterResponse;

  const data = [...firstQuarterData, ...secondQuarterResponse.data, ...thirdQuarterResponse.data, ...forthQuarterResponse.data];

  ReactGA.event('fetched_pws_data', {
    count: data.length,
  });

  return {
    ...accountInfo,
    data,
  };
};

const LogoutContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Logout = () => {
  const { clearAuthToken, clearAccountInfo } = useStore();
  const onLogout = () => {
    clearAuthToken();
    clearAccountInfo();
  };

  return (
    <LogoutContainer>
      <Button onClick={onLogout}>Logout</Button>
    </LogoutContainer>
  );
};

const SelectPowersensorAccount = () => {
  const { accountInfo, authToken, selectedAccount, setSelectedAccount, setUsage } = useStore();
  if (!accountInfo) return null;
  const accountOptions: string[] = [accountInfo.email, ...(accountInfo.dataSharedBy ?? []).map(data => data.email)];
  const onSuccess = (data: PowerSensorAccountData) => {
    const powerSensorUsage = powerSensorDataToUsageData(data);
    setUsage(powerSensorUsage);
  };
  const [fetchData, { loading, error }] = useMutation(fetchAnnualData, {
    onSuccess,
  });

  const onChange = (event: SelectChangeEvent) => setSelectedAccount(event.target.value as string);
  const onClick = selectedAccount ? () => fetchData({ email: selectedAccount, authToken: authToken ?? '' }) : undefined;

  return (
    <>
      <Logout />
      <FieldContainer title="Select Account">
        <Select disableUnderline={true} value={selectedAccount} onChange={onChange}>
          {accountOptions.map(t => (
            <MenuItem key={t} value={t}>
              {t}
            </MenuItem>
          ))}
        </Select>
      </FieldContainer>
      <Button onClick={onClick} disabled={loading || !selectedAccount} variant="contained">
        {loading ? <CircularProgress size={24} color="inherit" /> : 'Fetch Data'}
      </Button>
      {error && (
        <Typography variant="caption" color="error">
          {error}
        </Typography>
      )}
    </>
  );
};

export default SelectPowersensorAccount;
