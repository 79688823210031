import styled from '@emotion/styled';
import { Button, FormControl, InputLabel, MenuItem, Modal, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import BouncingDotsLoader from 'components/BouncingDotsLoader';
import ModalContainer from 'components/common/ModalContainer';
import useMutation from 'hooks/useMutation';
import { useState } from 'react';
import ReactGA from 'react-ga4';
import { AER_RETAILERS } from 'services/aer/retailers';
import { getAllPlanDetailsForAllRetailers } from 'services/aer/tariffPlan';
import { AERTariffToTariff } from 'services/aer/transform';
import { AERCustomerType, AERPlanDetailV3, AERPlanType } from 'services/aer/types';
import { useStore } from 'store';

const ALL = {
  brand: '-- All --',
  uri: 'all',
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
  max-width: 40rem;
  gap: 1rem;
`;

const FieldsWrapper = styled.div`
  display: flex;
  margin: 0.5rem 0;
  gap: 1rem;
`;

const StyledModalContainer = styled(ModalContainer)`
  justify-content: center;
  align-items: center;
`;

const SearchFormControl = styled(FormControl)`
  flex: 1;
`;

const onlyResidentialNonStandingPlans = (p: AERPlanDetailV3) =>
  p.customerType === AERCustomerType.RESIDENTIAL && p.type !== AERPlanType.STANDING;

// NOTE (pdiego): Noticed that controlledLoad sometimes is an array of objects or a single object
const toSantizedControlledLoad = (p: AERPlanDetailV3) =>
  !!p.electricityContract.controlledLoad && !Array.isArray(p.electricityContract.controlledLoad)
    ? {
        ...p,
        electricityContract: {
          ...p.electricityContract,
          controlledLoad: [p.electricityContract.controlledLoad],
        },
      }
    : p;

const onlyWithSameNumberOfFeeds = (feedCount: number) => (p: AERPlanDetailV3) =>
  (p.electricityContract.controlledLoad?.length || 0) === feedCount;

const PlansByPostcodeAndRetailer = () => {
  const { addComparisonTariff, controlledLoadData, clearComparisonTariffs } = useStore();
  const [postcode, setPostcode] = useState('');
  const [retailerUriPath, setRetailerUriPath] = useState<string>('');
  const [alert, setAlert] = useState('');
  const isSearchDisabled = !postcode || !retailerUriPath;

  const [fetchPlans, { loading }] = useMutation(getAllPlanDetailsForAllRetailers, {
    onSuccess: plans => {
      const residentialNonStandingPlans = plans.filter(onlyResidentialNonStandingPlans);
      const sanitizedControlledLoads = residentialNonStandingPlans.map(toSantizedControlledLoad);
      const plansWithSameNumberOfFeeds = sanitizedControlledLoads.filter(onlyWithSameNumberOfFeeds(controlledLoadData.length));

      ReactGA.event('retrieved_plans', {
        postcode,
        totalPlansFromAPI: plans.length,
        totalResidentialNonStandingPlans: residentialNonStandingPlans.length,
        totalDisplayedPlans: plansWithSameNumberOfFeeds.length,
        topDisplayedPlanName: plansWithSameNumberOfFeeds[0]?.displayName,
        topDisplayedPlanId: plansWithSameNumberOfFeeds[0]?.planId,
      });

      if (residentialNonStandingPlans.length === 0) {
        throw new Error(`No residential plans found for ${retailerUriPath} in ${postcode}`);
      }

      if (plansWithSameNumberOfFeeds.length === 0) {
        throw new Error(`No plans with the same number of feeds for ${retailerUriPath} in ${postcode}`);
      }

      for (const p of plansWithSameNumberOfFeeds) {
        try {
          addComparisonTariff(AERTariffToTariff(p));
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error('Error mapping tariff', p, e);

          ReactGA.event('plan_calc_fault', {
            planId: p.planId,
            displayName: p.displayName,
            retailerUriPath,
            postcode,
          });
        }
      }
    },
    onError: (e: Error) => setAlert(e.message),
  });
  const onSearch = () => {
    if (!isSearchDisabled) {
      const retailers = retailerUriPath === ALL.uri ? AER_RETAILERS.map(r => r.uri) : [retailerUriPath];
      clearComparisonTariffs();

      ReactGA.event('fetch_plans', {
        postcode,
        allRetailers: retailerUriPath === ALL.uri,
        retailerUriPath,
      });

      fetchPlans({ postcode, retailers });
    }
  };
  const handleRetailerChange = (event: SelectChangeEvent<unknown>) => setRetailerUriPath(event.target.value as string);
  return (
    <StyledContainer>
      <Typography variant="h5">Search for plans by postcode and retailer</Typography>
      <FieldsWrapper>
        <SearchFormControl>
          <TextField
            id="postcode-input"
            label="Postcode"
            value={postcode}
            onChange={e => setPostcode(e.target.value)}
            variant="outlined"
          />
        </SearchFormControl>
        <SearchFormControl>
          <InputLabel id="retailer-select">Retailer</InputLabel>
          <Select
            id="retailer-select"
            disableUnderline={true}
            value={retailerUriPath}
            onChange={handleRetailerChange}
            label="Retailer"
          >
            {[ALL, ...AER_RETAILERS].map(({ brand, uri }) => (
              <MenuItem key={uri} value={uri}>
                {brand}
              </MenuItem>
            ))}
          </Select>
        </SearchFormControl>
        <Button
          variant="contained"
          size="large"
          fullWidth={false}
          sx={{ margin: '0 auto' }}
          disabled={isSearchDisabled}
          onClick={onSearch}
          style={{ textTransform: 'none' }}
        >
          Search
        </Button>
      </FieldsWrapper>
      <Modal open={loading}>
        <StyledModalContainer>
          <Typography variant="h6" fontWeight="bold">
            Calculating Tariffs
          </Typography>
          <BouncingDotsLoader />
        </StyledModalContainer>
      </Modal>
      <Modal open={!!alert} onClose={() => setAlert('')}>
        <StyledModalContainer>
          <Typography variant="h6" fontWeight="bold">
            {alert}
          </Typography>
        </StyledModalContainer>
      </Modal>
    </StyledContainer>
  );
};

export default PlansByPostcodeAndRetailer;
