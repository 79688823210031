import { ReactNode } from 'react';

type VisibleProps = {
  on: boolean;
  children?: ReactNode;
};

const Visible: React.FC<VisibleProps> = ({ children, on }) => <>{on && children}</>;

export default Visible;
