import styled from '@emotion/styled';
import { Button, Typography } from '@mui/material';
import Add from '@mui/icons-material/Add';
import ClockBarRates from 'components/time/ClockBarRates';
import { PeriodTariffRate } from 'components/time/PeriodTariffRate';
import { PERIOD_TYPE_OPTIONS } from 'utils/constants';
import DashedDivider from 'components/common/DashedDivider';
import RateTypeLabel from 'components/time//RateTypeLabel';
import { PeriodType, TariffFormRate } from 'utils/types';

type TariffRatesByPeriodType = {
  [key in PeriodType]?: TariffFormRate[];
};

const StyledTariffRateContent = styled.div`
  padding-top: 0.5rem;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CopyButton = styled(Button)(({ theme }) => ({
  marginLeft: '1rem',
  border: `1px solid ${theme.palette.divider}`,
}));

const TimeOfUseTariffContainer = styled.div`
  margin: 3rem auto;
  max-width: 30rem;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const byPeriodTypeReducer = (acc: TariffRatesByPeriodType, current: TariffFormRate) => ({
  ...acc,
  [current.period.type]: [...(acc[current.period.type] || []), current],
});

type PeriodTariffRatesProps = {
  periodType: PeriodType;
  value: TariffFormRate[];
  onUpdate: (tariffRate: TariffFormRate) => void;
  onDelete: (tariffRate: TariffFormRate) => void;
};

const PeriodTariffRates: React.FC<PeriodTariffRatesProps> = ({ onDelete, onUpdate, value }) => (
  <>
    {value.map((t, i) => (
      <PeriodTariffRate onDelete={onDelete} data={t} key={i} onUpdate={onUpdate} />
    ))}
  </>
);

type TimeOfUseTariffRatesProps = {
  title: string;
  onAdd: (periodType: PeriodType) => void;
  addText?: string;
  copyText?: string;
  onCopy?: () => void;
  onDelete: (tariffRate: TariffFormRate) => void;
  onUpdate: (tariffRate: TariffFormRate) => void;
  data: TariffFormRate[];
  hasError?: boolean;
};

const TimeOfUseTariffRates: React.FC<TimeOfUseTariffRatesProps> = ({
  data,
  onAdd,
  addText = 'Add',
  copyText = 'Copy',
  onCopy,
  onUpdate,
  onDelete,
  title,
  hasError,
}) => {
  const tariffRatesByPeriodType: TariffRatesByPeriodType = data.reduce(byPeriodTypeReducer, {});

  return (
    <TimeOfUseTariffContainer>
      <TitleContainer>
        <Typography fontWeight="bold">{title}</Typography>
        {onCopy && (
          <CopyButton onClick={onCopy} size="small">
            {copyText}
          </CopyButton>
        )}
      </TitleContainer>
      <ClockBarRates rates={data} highlightUnfulfilled={hasError} />
      {hasError && (
        <Typography variant="caption" color="error">
          Please fill the rates for the entire day
        </Typography>
      )}
      {PERIOD_TYPE_OPTIONS.map((periodType, index) => (
        <div style={{ marginTop: '3rem' }} key={periodType}>
          <FlexContainer>
            <RateTypeLabel periodType={periodType} />
            <Button onClick={() => onAdd(periodType)} variant="contained" startIcon={<Add />} style={{ textTransform: 'none' }}>
              {addText}
            </Button>
          </FlexContainer>
          <StyledTariffRateContent>
            {tariffRatesByPeriodType[periodType] && (
              <PeriodTariffRates
                periodType={periodType}
                onUpdate={onUpdate}
                value={tariffRatesByPeriodType[periodType]!}
                onDelete={onDelete}
              />
            )}
          </StyledTariffRateContent>
          {index < PERIOD_TYPE_OPTIONS.length - 1 && <DashedDivider />}
        </div>
      ))}
    </TimeOfUseTariffContainer>
  );
};

export default TimeOfUseTariffRates;
