import { createApi } from 'services/http';

// NOTE(pdiego): This API provides the ability to compare available plans for a specific brand in a given location (postcode)
export const aerApi = createApi({
  baseURL: 'https://cdr.energymadeeasy.gov.au',
  headers: {
    Accept: '*/*',
    'x-min-v': 1,
    'x-v': 3,
  },
});
