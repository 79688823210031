import { Box, Typography } from '@mui/material';
import styled from '@emotion/styled';

const ContentContainer = styled(Box)(({ theme }) => ({
  flex: '1',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'left',
  padding: '3rem',
  [theme.breakpoints.up('md')]: {
    justifyContent: 'space-between',
    padding: '3rem 3rem 3rem 6rem',
  },
}));

const Instruction: React.FC = () => {
  return (
    <ContentContainer>
      <Typography variant="h6" gutterBottom>
        Start searching for plans now.
        <br />
      </Typography>
      <Typography variant="body1" gutterBottom>
        Login with your Powersensor account credentials. Or, upload your energy usage in a .csv file format.
      </Typography>
      <br />
      <Typography variant="h6" gutterBottom>
        How do I find my energy usage in .csv?
        <br />
      </Typography>
      <Typography variant="body1" gutterBottom>
        If you sign into your energy account (where you download your bills), most energy providers will give you the option
        download your energy usage data as a CSV file. If not you can request this from them.
      </Typography>
      <br />
    </ContentContainer>
  );
};

export default Instruction;
