import StepNavigation from 'components/StepNavigation';
import TariffForm from 'components/form/TariffForm';
import { useStore } from 'store';
import { Tariff } from 'utils/types';

const CustomerTariffForm = () => {
  const { addCustomerTariff, goToStep, nextStep, customerTariffs } = useStore();

  const onSubmit = (tariff: Tariff) => {
    addCustomerTariff(tariff);
    nextStep();
  };

  const actions = (
    <StepNavigation nextButton={{ type: 'submit', onClick: undefined }} skipButton={{ onClick: () => goToStep(3) }} />
  );

  return <TariffForm tariff={customerTariffs[0]} {...{ actions, onSubmit }} />;
};

export default CustomerTariffForm;
