import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyDNWY_ca72giP52rk-B04XtUHlYhAH0GJw',
  authDomain: 'dius-power-sensor.firebaseapp.com',
  databaseURL: 'https://dius-power-sensor.firebaseio.com',
  projectId: 'dius-power-sensor',
  storageBucket: 'dius-power-sensor.appspot.com',
  messagingSenderId: '624895364768',
  appId: '1:624895364768:web:4948758731d8c2d6aac3c5',
  measurementId: 'G-31SW8RME8Z',
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
