import styled from '@emotion/styled';
import { Button } from '@mui/material';
import FloatingAddButton from 'components/FloatingAddButton';
import TariffForm from 'components/form/TariffForm';
import { useStore } from 'store';
import { Tariff } from 'utils/types';

type TariffFormToggleProps = {
  isFormOpen: boolean;
  selectedTariff?: Tariff;
  toggleForm: () => void;
};

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 30rem;
`;

const SpacedContent = styled.div`
  margin-top: 1.5rem;
`;

const TariffFormToggle: React.FC<TariffFormToggleProps> = ({ isFormOpen, selectedTariff, toggleForm }) => {
  const { addCustomerTariff } = useStore();
  const onSubmit = (tariff: Tariff) => {
    addCustomerTariff(tariff);
    toggleForm();
  };

  const actions = (
    <ButtonWrapper>
      <Button size="large" variant="outlined" onClick={toggleForm}>
        Cancel
      </Button>
      <Button type="submit" size="large" variant="contained">
        Save
      </Button>
    </ButtonWrapper>
  );
  return isFormOpen ? (
    <TariffForm {...{ actions, onSubmit }} isCompare tariff={selectedTariff} />
  ) : (
    <SpacedContent>
      <FloatingAddButton onClick={toggleForm} />
    </SpacedContent>
  );
};

export default TariffFormToggle;
